import { useTheme } from 'styled-components';

import { OrderFormSides } from '../../../types/OrderFormSides';
import { format } from '../../../utils/number';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { BalanceLabel, MaxAvailableWrapper } from './styles';

import { defineMessages } from 'react-intl';
import type { Security } from '../../../types/Security';
import { FormattedMessage } from '../../Intl';

const messages = defineMessages({
  maxAvailable: {
    defaultMessage: 'Max Available',
    id: 'Forms.FormBalances.maxAvailable',
  },
});

export const MaxAvailable = ({
  maxBalance,
  formCurrency,
  security,
  side,
}: {
  maxBalance?: string;
  security: Security;
  formCurrency?: string | null;
  side?: OrderFormSides | null;
}) => {
  const {
    BaseCurrency,
    QuoteCurrency,
    DefaultPriceIncrement,
    DefaultSizeIncrement,
    MinPriceIncrement,
    MinSizeIncrement,
  } = security;
  const currency = side === OrderFormSides.Sell ? BaseCurrency : QuoteCurrency;
  const increment = formCurrency === BaseCurrency ? DefaultSizeIncrement : DefaultPriceIncrement;
  const minIncrement = formCurrency === BaseCurrency ? MinSizeIncrement : MinPriceIncrement;

  const { backgroundContent } = useTheme();

  if (side === OrderFormSides.Twoway || maxBalance == null) {
    return null;
  }

  const roundedMaxBalance = format(maxBalance, { pretty: false, spec: minIncrement, round: true });

  return (
    <MaxAvailableWrapper>
      <BalanceLabel>
        <FormattedMessage {...messages.maxAvailable} />
      </BalanceLabel>
      {formCurrency != null ? (
        <InlineFormattedNumber
          number={roundedMaxBalance}
          increment={increment}
          currency={formCurrency}
          estimate={currency !== formCurrency}
          background={backgroundContent}
          align="right"
        />
      ) : (
        ''
      )}
    </MaxAvailableWrapper>
  );
};
