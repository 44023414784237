import Big from 'big.js';
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { OrderFormSides } from '../../../types/OrderFormSides';
import type { FormState, FormTouchedState, FormValidationState } from '../types';

import { isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';
import { useMixpanel } from '../../../contexts';
import { useMaxBalance } from '../../../hooks';
import { MixpanelEvent } from '../../../tokens';
import type { Balance } from '../../../types/Balance';
import type { CustomerQuote } from '../../../types/CustomerQuote';
import type { Security } from '../../../types/Security';
import { QuoteStatusEnum, SideEnum } from '../../../types/types';
import { bpsToPercent, format } from '../../../utils/number';
import { Button, ButtonGroup, ButtonVariants } from '../../Button';
import { QuoteButton } from '../../Button/QuoteButton';
import { Box, HStack } from '../../Core';
import { Crossmark } from '../../Crossmark';
import { CurrencyPairSelector } from '../../CurrencyPairSelector';
import { CollapsibleFormSection, FormGroup, FormSection, Label, ManagedCollapsibleFormSection } from '../../Form';
import { InlineFormattedNumber, StackedFormattedNumber } from '../../FormattedNumber';
import { FormattedMessage } from '../../Intl';
import { DEFAULT_SLIPPAGES, SlippageControl, SlippageWrapper, type Slippage } from '../../Slippage';
import { Spinner } from '../../Spinner';
import { Text } from '../../Text';
import { CustomerBalanceDetailsHeader } from '../CustomerBalanceDetails/CustomerBalanceDetailsHeader';
import { MaxAvailable } from '../FormBalances';
import { QuantityInput } from '../QuantityInput';
import { FormFooter, FormWrapper, ScrollContainer } from '../styles';
import { RemainingTime } from './RemainingTime';
import { ErrorMessage, QuoteAmount, QuoteButtons, QuotePrice, QuoteSide, QuoteSpread } from './styles';
import { getQuoteSpreadDisplay } from './utils';

const messages = defineMessages({
  account: {
    defaultMessage: 'Account',
    id: 'Forms.RFQForm.account',
  },
  buy: {
    defaultMessage: 'Buy',
    id: 'Forms.RFQForm.buy',
  },
  buyCurrency: {
    defaultMessage: 'Buy {currency}',
    id: 'Forms.RFQForm.buyCurrency',
  },
  getQuotes: {
    defaultMessage: 'Get {numSides, plural, one {Quote} other {Quotes}}',
    id: 'Forms.RFQForm.getQuotes',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'Forms.RFQForm.sell',
  },
  sellCurrency: {
    defaultMessage: 'Sell {currency}',
    id: 'Forms.RFQForm.sellCurrency',
  },
  summary: {
    defaultMessage: 'Summary',
    id: 'Forms.RFQForm.summary',
  },
  twoWay: {
    defaultMessage: 'Two-Way',
    id: 'Forms.RFQForm.twoWay',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'Forms.RFQForm.quantity',
  },
  symbolLabel: {
    defaultMessage: 'I am {side, select, Buy {Buying} Sell {Selling} other {buying/selling}}',
    id: 'Forms.RFQForm.symbolLabel',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Forms.RFQForm.cancel',
  },
  clear: {
    defaultMessage: 'Clear',
    id: 'Forms.RFQForm.clear',
  },
});

type Callback = (values: FormState) => void;
type RFQFormProps = {
  security: Security;
  form: FormState;
  errors: FormValidationState;
  quote?: CustomerQuote;
  requesting?: boolean;
  sending?: boolean;
  homeCurrency?: string;
  touched: FormTouchedState;
  enableTwoWayQuotes: boolean;
  useColoredButtonsForTwoWayQuotes?: boolean;
  enableCurrencyPairSelector?: boolean;
  showTwowaySpread?: boolean;
  balances?: Map<string, Balance>;
  rejectedQuoteText?: string;
  onFormChange: React.Dispatch<React.SetStateAction<FormState>>;
  setTouched: (values: FormTouchedState) => void;
  onFormSubmit: Callback;
  onFormClear: () => void;
  onCancelQuote: () => void;
  onAcceptQuote: (quote: CustomerQuote, side: SideEnum) => void;
  onSymbolChange?: (symbol: string) => void;
  marketAccountSelector?: ReactElement;
  showOrderSlippage?: boolean;
  balanceDetails?: ReactNode;
  orderSummary?: ReactNode;
};

export const RFQForm = ({
  errors,
  form,
  requesting,
  sending,
  quote,
  security,
  homeCurrency,
  touched,
  balances,
  enableTwoWayQuotes,
  useColoredButtonsForTwoWayQuotes,
  enableCurrencyPairSelector,
  showTwowaySpread,
  setTouched,
  onFormChange,
  onFormSubmit,
  onFormClear,
  onAcceptQuote,
  onCancelQuote,
  onSymbolChange = () => {},
  marketAccountSelector,
  showOrderSlippage,
  balanceDetails,
  orderSummary,
}: RFQFormProps) => {
  const { OrderQty, Currency, MarketAccount, Side } = form;
  const { BaseCurrency, QuoteCurrency, MinSizeIncrement, MinAmtIncrement, MinPriceIncrement } = security;
  const mixpanel = useMixpanel();
  const [allowedSlippage, setAllowedSlippage] = useState<Slippage>(DEFAULT_SLIPPAGES[2]);
  const [balanceDetailsExpanded, setBalanceDetailsExpanded] = useState(false);
  const baseBalance = balances?.get(BaseCurrency);
  const quoteBalance = balances?.get(QuoteCurrency);

  const sideCurrency = Side === OrderFormSides.Sell ? BaseCurrency : QuoteCurrency;
  const maxBalance = useMaxBalance(balances, sideCurrency, Currency);

  const handleAcceptQuote = useCallback(
    (side: SideEnum) => {
      if (quote?.QuoteStatus === QuoteStatusEnum.Open) {
        onAcceptQuote(quote, side);
      }
    },
    [quote, onAcceptQuote]
  );

  const handleFormSubmit = useCallback(
    e => {
      e.preventDefault();
      setTouched({ OrderQty: true });
      onFormSubmit(form);
    },
    [onFormSubmit, form, setTouched]
  );

  const runValidation = useCallback(
    e => {
      if (e.key === 'Enter') {
        setTouched({ OrderQty: true });
      }
    },
    [setTouched]
  );

  const handleUpdateQty = useCallback(
    (OrderQty?: string): void => onFormChange(prev => ({ ...prev, OrderQty })),
    [onFormChange]
  );
  const handleUpdateCcy = useCallback(
    (Currency?: string): void => onFormChange(prev => ({ ...prev, Currency, OrderQty: '' })),
    [onFormChange]
  );
  const handleUpdateSide = useCallback(
    (e: MouseEvent<HTMLButtonElement>) =>
      onFormChange(prev => ({
        ...prev,
        Side: OrderFormSides[e.currentTarget.value as keyof typeof OrderFormSides] ?? OrderFormSides.Twoway,
      })),
    [onFormChange]
  );
  const handleOnQuickOptionClicked = useCallback(
    (value: number) => {
      if (maxBalance == null) {
        return null;
      }
      onFormChange(prev => ({
        ...prev,
        OrderQty: format(Big(maxBalance).times(value), {
          spec: (Currency === BaseCurrency ? MinSizeIncrement : MinAmtIncrement || MinPriceIncrement) || '0.01',
          pretty: false,
          truncate: true,
        }),
      }));
    },
    [maxBalance, onFormChange, MinAmtIncrement, Currency, BaseCurrency, MinSizeIncrement, MinPriceIncrement]
  );
  const handleAllowedSlippage = useCallback(
    (slippage: Slippage) => {
      setAllowedSlippage(slippage);
      mixpanel.track(MixpanelEvent.ChangeAllowedSlippage);
      onFormChange(prev => ({
        ...prev,
        AllowedSlippage: bpsToPercent(slippage.value || '0'),
      }));
    },
    [mixpanel, onFormChange]
  );

  useEffect(() => {
    // Default our form on load, but only if the flag is set
    if (showOrderSlippage) {
      handleAllowedSlippage(DEFAULT_SLIPPAGES[2]);
    }
  }, [showOrderSlippage, handleAllowedSlippage]);

  const qtyQuickOptionsDisabled = useMemo(
    () => Side == null || Side === OrderFormSides.Twoway || !maxBalance,
    [Side, maxBalance]
  );

  const hasQuote =
    quote != null && quote.QuoteStatus !== QuoteStatusEnum.PendingNew && quote.QuoteStatus !== QuoteStatusEnum.Rejected;
  const rejectedQuoteText = quote?.QuoteStatus === QuoteStatusEnum.Rejected ? quote?.Text : undefined;
  const disableForm = requesting || hasQuote || !!rejectedQuoteText;
  const expiredQuote = quote?.QuoteStatus === QuoteStatusEnum.Canceled;

  let quoteSpread = '-';
  if (showTwowaySpread && quote?.QuoteStatus === QuoteStatusEnum.Open && quote?.BidPx && quote?.OfferPx) {
    quoteSpread = getQuoteSpreadDisplay(quote.BidPx, quote.OfferPx);
  }

  return (
    <FormWrapper onKeyUp={runValidation} onSubmit={handleFormSubmit} autoComplete="off">
      <ScrollContainer>
        <FormSection>
          <FormGroup>
            <ButtonGroup>
              <Button
                disabled={disableForm}
                onClick={handleUpdateSide}
                value={OrderFormSides.Buy}
                variant={Side === OrderFormSides.Buy ? ButtonVariants.Positive : ButtonVariants.Default}
                data-testid="rfq-form-header-buy-button"
              >
                <FormattedMessage {...messages.buy} />
              </Button>
              {enableTwoWayQuotes && (
                <Button
                  disabled={disableForm}
                  onClick={handleUpdateSide}
                  value={OrderFormSides.Twoway}
                  variant={Side === OrderFormSides.Twoway ? ButtonVariants.Primary : ButtonVariants.Default}
                  data-testid="order-form-header-two-way-button"
                >
                  <FormattedMessage {...messages.twoWay} />
                </Button>
              )}
              <Button
                disabled={disableForm}
                onClick={handleUpdateSide}
                value={OrderFormSides.Sell}
                variant={Side === OrderFormSides.Sell ? ButtonVariants.Negative : ButtonVariants.Default}
                data-testid="rfq-form-header-sell-button"
              >
                <FormattedMessage {...messages.sell} />
              </Button>
            </ButtonGroup>
          </FormGroup>
          {enableCurrencyPairSelector && (
            <FormGroup
              disabled={disableForm}
              label={<FormattedMessage {...messages.symbolLabel} values={{ side: Side }} />}
            >
              <CurrencyPairSelector
                symbol={security.Symbol}
                side={Side ?? undefined}
                disabled={disableForm}
                homeCurrency={homeCurrency}
                onChange={onSymbolChange}
              />
            </FormGroup>
          )}
          {marketAccountSelector && (
            <FormGroup
              error={touched.MarketAccount ? errors?.MarketAccount : undefined}
              label={<FormattedMessage {...messages.account} />}
            >
              {cloneElement(marketAccountSelector, {
                invalid: !!(touched.MarketAccount && errors?.MarketAccount),
              })}
            </FormGroup>
          )}
          <FormGroup
            disabled={disableForm}
            error={touched.OrderQty ? errors?.OrderQty : undefined}
            warning={touched.OrderQty && form.FormWarningType === 'warn' ? form.FormWarningMessage : undefined}
            controlId="OrderQty"
            labelWidth="100%"
          >
            <HStack justifyContent="space-between">
              <Label htmlFor="OrderQty">
                <FormattedMessage {...messages.quantity} />
              </Label>
              {balances != null && (
                <MaxAvailable maxBalance={maxBalance} security={security} formCurrency={Currency} side={Side} />
              )}
            </HStack>
            <QuantityInput
              autoFocus
              disabled={disableForm}
              side={Side ?? OrderFormSides.Buy}
              value={OrderQty ?? ''}
              touched={!!touched.OrderQty}
              invalid={!!errors?.OrderQty}
              showQuickOptions={true}
              disableQuickOptions={qtyQuickOptionsDisabled}
              onQuickOptionClicked={handleOnQuickOptionClicked}
              currency={Currency}
              onChange={handleUpdateQty}
              onCurrencyChange={handleUpdateCcy}
              security={security}
              min="0"
              type="number"
              name="OrderQty"
              id="OrderQty"
            />
          </FormGroup>
        </FormSection>
        {disableForm && (
          <FormSection>
            {showOrderSlippage && (
              <Box display="flex" alignItems="center" justifyContent="flex-end" mb="spacingSmall">
                <SlippageWrapper>
                  <SlippageControl slippage={allowedSlippage} onApply={handleAllowedSlippage} />
                </SlippageWrapper>
              </Box>
            )}
            <QuoteButtons>
              {Side !== OrderFormSides.Buy && (
                <QuoteButton
                  disabled={sending || expiredQuote || quote?.BidPx == null}
                  onClick={() => handleAcceptQuote(SideEnum.Sell)}
                  variant={useColoredButtonsForTwoWayQuotes ? ButtonVariants.Negative : getRFQButtonVariant(Side)}
                >
                  {hasQuote ? (
                    <>
                      <QuoteSide>
                        <FormattedMessage {...messages.sellCurrency} values={{ currency: security.BaseCurrency }} />
                      </QuoteSide>
                      <QuotePrice>
                        <StackedFormattedNumber
                          number={quote?.BidPx}
                          specification={security.PriceDisplaySpec}
                          highlightAll={false}
                        />
                      </QuotePrice>
                      <QuoteAmount>
                        <InlineFormattedNumber
                          increment={
                            Currency === security.BaseCurrency
                              ? security.DefaultPriceIncrement
                              : security.DefaultSizeIncrement
                          }
                          number={quote?.BidAmt}
                          currency={Currency === security.BaseCurrency ? security.QuoteCurrency : security.BaseCurrency}
                        />
                      </QuoteAmount>
                    </>
                  ) : rejectedQuoteText ? (
                    <Crossmark />
                  ) : (
                    <Spinner />
                  )}
                </QuoteButton>
              )}
              {Side !== OrderFormSides.Sell && (
                <QuoteButton
                  disabled={sending || expiredQuote || quote?.OfferPx == null}
                  onClick={() => handleAcceptQuote(SideEnum.Buy)}
                  variant={useColoredButtonsForTwoWayQuotes ? ButtonVariants.Positive : getRFQButtonVariant(Side)}
                >
                  {hasQuote ? (
                    <>
                      <QuoteSide>
                        <FormattedMessage {...messages.buyCurrency} values={{ currency: security.BaseCurrency }} />
                      </QuoteSide>
                      <QuotePrice>
                        <StackedFormattedNumber
                          number={quote?.OfferPx}
                          specification={security.PriceDisplaySpec}
                          highlightAll={false}
                        />
                      </QuotePrice>
                      <QuoteAmount>
                        <InlineFormattedNumber
                          increment={
                            Currency === security.BaseCurrency
                              ? security.DefaultPriceIncrement
                              : security.DefaultSizeIncrement
                          }
                          number={quote?.OfferAmt}
                          currency={Currency === security.BaseCurrency ? security.QuoteCurrency : security.BaseCurrency}
                        />
                      </QuoteAmount>
                    </>
                  ) : rejectedQuoteText ? (
                    <Crossmark />
                  ) : (
                    <Spinner />
                  )}
                </QuoteButton>
              )}
              {showTwowaySpread && Side === OrderFormSides.Twoway && quoteSpread && quoteSpread !== '-' && (
                <QuoteSpread>{quoteSpread}</QuoteSpread>
              )}
            </QuoteButtons>
            <RemainingTime
              validUntilTime={quote?.QuoteStatus === QuoteStatusEnum.Open ? quote?.ValidUntilTime : undefined}
              loading={!!requesting}
            />
            {quote?.Text != null && <ErrorMessage>{quote?.Text}</ErrorMessage>}
          </FormSection>
        )}
      </ScrollContainer>
      {balanceDetails && (
        <ManagedCollapsibleFormSection
          header={
            <CustomerBalanceDetailsHeader
              baseAmount={baseBalance?.AvailableAmount}
              isExpanded={balanceDetailsExpanded}
              marketAccount={MarketAccount}
              quoteAmount={quoteBalance?.AvailableAmount}
              security={security}
            />
          }
          isExpanded={balanceDetailsExpanded}
          onToggle={setBalanceDetailsExpanded}
        >
          {balanceDetails}
        </ManagedCollapsibleFormSection>
      )}
      {orderSummary && (
        <CollapsibleFormSection
          header={
            <Text color="colorTextAttention">
              <FormattedMessage {...messages.summary} />
            </Text>
          }
        >
          {orderSummary}
        </CollapsibleFormSection>
      )}
      <FormFooter>
        <Button onClick={disableForm ? onCancelQuote : onFormClear}>
          {disableForm ? <FormattedMessage {...messages.cancel} /> : <FormattedMessage {...messages.clear} />}
        </Button>
        <div onMouseEnter={() => setTouched({ OrderQty: true, MarketAccount: true })}>
          <Button disabled={disableForm || !isEmpty(errors)} variant={getRFQButtonVariant(Side)} type="submit">
            <FormattedMessage {...messages.getQuotes} values={{ numSides: Side === OrderFormSides.Twoway ? 2 : 1 }} />
          </Button>
        </div>
      </FormFooter>
    </FormWrapper>
  );
};

function getRFQButtonVariant(side?: OrderFormSides | null) {
  return side === OrderFormSides.Buy
    ? ButtonVariants.Positive
    : side === OrderFormSides.Sell
    ? ButtonVariants.Negative
    : ButtonVariants.Primary;
}
