import { useMemo } from 'react';

import {
  BlotterDensity,
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWLHomeCurrency,
  useWsBlotterTable,
  type Balance,
  type DateRangeFilter,
  type WebsocketRequest,
} from '@talos/kyoko';

import { map, pipe } from 'rxjs';

import { pick } from 'lodash';
import { useBalancesFilter } from '../../../../components/Filters';
import { useColumns, type PipedBalance } from './useColumns';

const generateRowID = (balance: Balance) => `${balance.MarketAccount}-${balance.Currency}`;

// Create a MarketAccount-Currency composite id for each datapoint received
const balanceCompositeIDPipe = pipe(
  map((result: { data: Balance[] }) => {
    return {
      ...result,
      data: result.data.map(balance => ({
        ...balance,
        RowID: generateRowID(balance),
      })),
    };
  })
);

interface BalancesBlotterWebsocketRequest extends WebsocketRequest {
  versionBy: string;
  EquivalentCurrency: string;
  ShowZeroBalances: boolean;
}

interface UseBalancesBlotterProps {
  renderEmpty: () => JSX.Element;
  showZeroBalances: boolean;
}

const SEARCH_KEYS: (keyof PipedBalance)[] = ['Currency', 'MarketAccount', 'Amount', 'AvailableAmount'];
const DEFAULT_SORT = '-Equivalent.AvailableAmount';

export const useBalancesBlotter = ({ renderEmpty, showZeroBalances }: UseBalancesBlotterProps) => {
  const { homeCurrency } = useWLHomeCurrency();
  const columns = useColumns();
  const request: BalancesBlotterWebsocketRequest = useConstant({
    name: 'Balance',
    tag: 'BLOTTER_BALANCES',
    versionBy: 'Revision',
    Throttle: '1s',
    EquivalentCurrency: homeCurrency,
    ShowZeroBalances: true,
    sort_by: DEFAULT_SORT,
  });

  const persistedBlotterTable = usePersistedBlotterTable('balances', {
    columns,
    sort: DEFAULT_SORT as any,
  });

  const filteredTrades = useBalancesFilter({ persistedBlotterTable, showZeroBalances });
  const { initialFilter, clientSideFilter: clientLocalFilter, filterBuilderProps } = filteredTrades;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });
  const compositePipe = useConstant(pipe(balanceCompositeIDPipe));

  const blotterTable = useWsBlotterTable<BalancesBlotterWebsocketRequest, PipedBalance>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    initialFilter: onlyServerFilterKeys(initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onFilterChanged: persistedBlotterTable.onFilterChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'RowID',
    clientLocalFilter,
    animateRows: true,
    flashRows: ['add'],
    density: BlotterDensity.Comfortable,
    rowHeight: 40,
    pipe: compositePipe,
    renderEmpty,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
    },
  });

  return useMemo(() => ({ blotterTable, filterBuilderAccordion }), [blotterTable, filterBuilderAccordion]);
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate']);
}
