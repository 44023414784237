import type { RowNode } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  ButtonVariants,
  FormRowStatus,
  FormTable,
  FormattedMessage,
  HStack,
  IconName,
  NotificationVariants,
  Portal,
  VStack,
  copyText,
  logger,
  promisesHaveResolved,
  useFormTable,
  useGlobalToasts,
  useIntl,
  useWLUserContext,
  type Column,
  type CustomerUserApiKey,
} from '@talos/kyoko';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  generateApiKey: {
    defaultMessage: 'Generate API Key',
    id: 'Settings.generateApiKey',
  },
  save: {
    defaultMessage: 'Save',
    id: 'Settings.save',
  },
  apiKey: {
    defaultMessage: 'API Key',
    id: 'Settings.apiKey',
  },
  apiSecret: {
    defaultMessage: 'API Secret',
    id: 'Settings.apiSecret',
  },
  whitelistedIps: {
    defaultMessage: 'Whitelisted IPs',
    id: 'Settings.whitelistedIps',
  },
});

interface UserApiKeysFormTableProps {
  portalID: string;
  userID: string;
}

function getApiSecret(data: CustomerUserApiKey) {
  return data.ApiSecret || '*****';
}

function useColumns() {
  const { add: addToast } = useGlobalToasts();
  const { formatMessage } = useIntl();

  return useMemo<Column[]>(
    () => [
      { type: 'date', field: 'Timestamp', width: 150, sort: '-' },
      {
        type: 'text',
        field: 'ApiKey',
        title: formatMessage(messages.apiKey),
        width: 130,
      },
      {
        type: 'custom',
        field: 'ApiSecret',
        title: formatMessage(messages.apiSecret),
        width: 220,
        params: {
          valueGetter: ({ data }: { data: CustomerUserApiKey }) => getApiSecret(data),
        },
      },
      {
        type: 'text',
        field: 'WhitelistedIps',
        title: formatMessage(messages.whitelistedIps),
        editable: true,
        width: 130,
      },
      { type: 'filler', id: 'filler' },
      {
        type: 'iconButton',
        id: 'copy',
        params: {
          icon: IconName.ClipboardCopy,
          onClick: ({ node }: { node: RowNode }) => {
            const rowData: CustomerUserApiKey = node.data;
            copyText(`USER:${rowData.CustomerUserID}
KEY:${rowData.ApiKey}
SECRET:${getApiSecret(rowData)}`);
            addToast({
              text: `Details copied to clipboard.`,
              variant: NotificationVariants.Default,
            });
          },
        },
        width: 40,
      },
      { type: 'remove', id: 'remove' },
    ],
    [addToast, formatMessage]
  );
}

export function UserApiKeysFormTable({ portalID, userID }: UserApiKeysFormTableProps) {
  const { createUserApiKey, deleteUserApiKey, getUserApiKeys, updateUserApiKey } = useWLUserContext();
  const { add: addToast } = useGlobalToasts();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [userApiKeys, setUserApiKeys] = useState<CustomerUserApiKey[] | undefined>();
  const columns = useColumns();

  const formTable = useFormTable<CustomerUserApiKey>({
    rowID: 'CustomerUserApiKeyID',
    data: userApiKeys,
    columns,
  });

  const handleSave = useCallback(() => {
    setIsSaving(true);
    const rows = formTable.getRows();
    const requests: Promise<any>[] = [];
    for (const row of rows) {
      if (row.status === FormRowStatus.Updated) {
        requests.push(
          updateUserApiKey(row.data)
            .then(({ data }) => {
              row.setData(data[0]);
              return data[0];
            })
            .catch(e => {
              logger.error(e as Error);
              addToast({
                text: e?.toString() || `Could not update api key.`,
                variant: NotificationVariants.Negative,
              });
            })
        );
      }
      if (row.status === FormRowStatus.Removed) {
        requests.push(
          deleteUserApiKey(row.data.CustomerUserID, row.data.CustomerUserApiKeyID)
            .then(() => {
              row.remove(true);
              return row.data;
            })
            .catch(e => {
              logger.error(e as Error);
              addToast({
                text: e?.toString() || `Could not delete api key.`,
                variant: NotificationVariants.Negative,
              });
            })
        );
      }
    }
    Promise.allSettled(requests).then(promises => {
      setIsSaving(false);
      if (promisesHaveResolved(promises)) {
        addToast({
          text: 'Customer User API Keys saved.',
          variant: NotificationVariants.Positive,
        });
      } else {
        addToast({
          text: 'Customer User API Keys could not be saved.',
          variant: NotificationVariants.Negative,
        });
      }
    });
  }, [addToast, deleteUserApiKey, updateUserApiKey, formTable]);

  useEffect(() => {
    getUserApiKeys(userID).then(({ data }) => setUserApiKeys(data));
  }, [getUserApiKeys, setUserApiKeys, userID]);

  return (
    <VStack alignItems="stretch" h="100%" w="100%">
      <Portal portalId={portalID}>
        <HStack gap="spacingDefault">
          <Button
            startIcon={IconName.Plus}
            variant={ButtonVariants.Positive}
            onClick={() =>
              createUserApiKey(userID).then(({ data }) => {
                // Doesn't need to be saved, already confirmed to be generated
                formTable.addRow(data[0], FormRowStatus.None);
              })
            }
          >
            <FormattedMessage {...messages.generateApiKey} />
          </Button>
          <Button variant={ButtonVariants.Primary} onClick={handleSave} disabled={isSaving || !formTable.isDirty}>
            <FormattedMessage {...messages.save} />
          </Button>
        </HStack>
      </Portal>
      <FormTable {...formTable} />
    </VStack>
  );
}
