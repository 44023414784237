import type { AgGridEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { compact, xor } from 'lodash';
import { useEffect } from 'react';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { useNamedState } from '../../../hooks/useNamedState';

/**
 * A hook to trigger a callback every time the rendered rows in an AgGrid changes.
 *
 * @param gridApi Api object for the grid this hook belongs to
 * @param callback The function to be called when the rendered rows changes
 */
export function useRenderedRows<TData = unknown>(
  gridApi: GridApi | null | undefined,
  callback: (event: AgGridEvent<TData>, rowIds: string[]) => void
) {
  const [renderedRowIds, setRenderedRowIds] = useNamedState<string[]>([], 'renderedRowIds');

  const filteredCallback = useDynamicCallback((event: AgGridEvent<TData>) => {
    const latestRenderedRowIds = compact(
      event.api
        .getRenderedNodes()
        .map(({ id }) => id)
        .sort()
    );
    if (xor(renderedRowIds, latestRenderedRowIds).length) {
      setRenderedRowIds(latestRenderedRowIds);
      callback(event, latestRenderedRowIds);
    }
  });
  useEffect(() => {
    if (!gridApi) {
      return;
    }

    gridApi.addEventListener('firstDataRendered', filteredCallback);
    gridApi.addEventListener('modelUpdated', filteredCallback);
    gridApi.addEventListener('bodyScrollEnd', filteredCallback);
    return () => {
      gridApi.removeEventListener('firstDataRendered', filteredCallback);
      gridApi.removeEventListener('modelUpdated', filteredCallback);
      gridApi.removeEventListener('bodyScrollEnd', filteredCallback);
    };
  }, [gridApi, filteredCallback]);
}
