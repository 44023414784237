import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  createCSVFileName,
  FormattedMessage,
  FormControlSizes,
  MixpanelEvent,
  useMixpanel,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import type { TabbedBlotterProps } from '../types';
import { usePositionsBlotter } from './hooks/usePositionsBlotter';

const messages = defineMessages({
  exportPositions: {
    defaultMessage: 'Export Positions',
    id: 'Blotters.PositionsBlotter.exportPositions',
  },
});

export type PositionsBlotterProps = Required<Pick<TabbedBlotterProps, 'hidden'>>;

export const PositionsBlotter = ({ hidden }: PositionsBlotterProps) => {
  const mixpanel = useMixpanel();

  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = usePositionsBlotter();

  const handleExportPositions = useCallback(() => {
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'Positions',
      }),
    });
    mixpanel.track(MixpanelEvent.ExportPositions);
  }, [blotterTable, mixpanel]);

  return (
    <>
      {!hidden && (
        <BlotterTableFilters
          {...filterBuilderAccordion}
          {...blotterTableFilterProps}
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={true}
          prefix={
            <Button size={FormControlSizes.Small} onClick={handleExportPositions}>
              <FormattedMessage {...messages.exportPositions} />
            </Button>
          }
        />
      )}
      <BlotterTable {...blotterTable!} />
    </>
  );
};
