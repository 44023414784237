import { type UpdateActionEnum, useObservableValue, useStaticSubscription } from '@talos/kyoko';

/**
 * Hook to get the Current Fee Tier using the CurrentFeeTier subscription
 * @returns {string | undefined} The current name of the fee tier
 */
export const useCurrentFeeTier = (): string | undefined => {
  const { data: currentFeeTierObs } = useStaticSubscription<{
    FeeTier?: string;
    UpdateAction: UpdateActionEnum;
  }>({ name: 'CurrentFeeTier', tag: 'USE_CURRENT_FEE_TIER' });

  return useObservableValue(() => currentFeeTierObs, [currentFeeTierObs])?.data.at(0)?.FeeTier;
};
