import {
  Box,
  Button,
  ButtonGroup,
  CustomerUserConfigLayoutType,
  Divider,
  Drawer,
  FormControlSizes,
  FormGroup,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  SidebarContext,
  SidebarItem,
  SidebarSection,
  SidebarThemeToggle,
  Tooltip,
  themes,
  useAuthContext,
  useMixpanel,
  useUserContext,
  useWLLayoutType,
  useWLOrgConfigContext,
  type DrawerProps,
  type ThemeTypes,
} from '@talos/kyoko';
import { useCallback, useMemo } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';
import { useNavigationItems } from '../../../hooks/useNavigationItems';
import { Logo } from '../../Logo';
import { ContentWrapper } from './styles';

export const NavigationDrawer = ({
  themeType,
  onChangeThemeType,
  openSupportModal,
  openDemoModal,
  isTalosWLDemoEnv,
  ...drawer
}: {
  openSupportModal: () => void;
  openDemoModal: () => void;
  isTalosWLDemoEnv: boolean;
  themeType: ThemeTypes;
  onChangeThemeType(themeType: ThemeTypes): void;
} & DrawerProps) => {
  const { user } = useUserContext();
  const { config: customerConfig } = useWLOrgConfigContext();
  const { layoutType, setLayoutType, tradingLayout } = useWLLayoutType();

  const mixpanel = useMixpanel();

  const handleChangeLayout = useCallback(
    e => {
      mixpanel.track(MixpanelEvent.ChangeLayout, { [MixpanelEventProperty.Type]: e.target.value });
      setLayoutType(e.target.value);
    },
    [setLayoutType, mixpanel]
  );

  const { logout } = useAuthContext();

  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';

  const navItems = useNavigationItems();

  const theme = useTheme();
  const sidebarTheme = (theme.sidebarThemeName && themes[theme.sidebarThemeName]) || theme;

  const handleDrawerClose = drawer.close;

  const value = useMemo(
    () => ({
      isExpanded: true,
      isPinned: false,
      showSubNavItems: false,
      setExpanded: () => {},
      setPinned: () => {},
      setShowSubNavItems: () => {},
    }),
    []
  );

  const displayName = (
    <SidebarItem disableHoverStyles={customerConfig.hideUserEmail} icon={IconName.UserCircle}>
      {user.DisplayName}
    </SidebarItem>
  );
  const userInfo = customerConfig.hideUserEmail ? displayName : <Tooltip tooltip={user.Email}>{displayName}</Tooltip>;

  return (
    <ThemeProvider theme={sidebarTheme}>
      <SidebarContext.Provider value={value}>
        <Drawer {...drawer}>
          <ContentWrapper>
            <SidebarSection>
              <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                <Box p="spacingDefault">
                  <Logo themeType={sidebarTheme.type} />
                </Box>
                <IconButton size={FormControlSizes.Small} ghost icon={IconName.Close} onClick={handleDrawerClose} />
              </Box>
              {navItems.map(
                ({
                  path,
                  icon,
                  hasSubNavItems,
                  label,
                }: {
                  path: string;
                  icon?: string;
                  hasSubNavItems?: boolean;
                  label: string;
                }) => {
                  return (
                    <SidebarItem key={path} to={path} icon={icon} hasSubNavItems={hasSubNavItems}>
                      {label}
                    </SidebarItem>
                  );
                }
              )}
            </SidebarSection>
            <SidebarSection mt="auto">
              {(customerConfig.menuItems?.length ?? 0) > 0 &&
                customerConfig.menuItems?.map(item => (
                  <a key={`${item.label}_${item.url}`} href={item.url} target="_blank" rel="noreferrer">
                    <SidebarItem icon={IconName.Exit}>{item.label}</SidebarItem>
                  </a>
                ))}
            </SidebarSection>
            <SidebarSection>
              <SidebarItem icon={IconName.Support} onClick={openSupportModal}>
                Support
              </SidebarItem>
              <SidebarItem icon={IconName.Logout} onClick={logout}>
                Log Out
              </SidebarItem>
              {customerConfig?.allowThemeChange !== false && (
                <>
                  <Divider />
                  <SidebarThemeToggle themeType={themeType} onChangeThemeType={onChangeThemeType} />
                </>
              )}
              {!customerConfig.navigationItems && (!isProd || customerConfig?.allowLayoutChange) && (
                <FormGroup mb={0}>
                  <ButtonGroup size={FormControlSizes.Small}>
                    <Button
                      disabled={layoutType === CustomerUserConfigLayoutType.SimpleRFQLayout}
                      value={CustomerUserConfigLayoutType.SimpleRFQLayout}
                      onClick={handleChangeLayout}
                    >
                      RFQ layout
                    </Button>
                    <Button disabled={layoutType === tradingLayout} value={tradingLayout} onClick={handleChangeLayout}>
                      Trading layout
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              )}
              {isTalosWLDemoEnv && (
                <SidebarItem onClick={openDemoModal} icon={IconName.PresentationChartBar}>
                  Demo Settings
                </SidebarItem>
              )}
              <Divider />
              {userInfo}
            </SidebarSection>
          </ContentWrapper>
        </Drawer>
      </SidebarContext.Provider>
    </ThemeProvider>
  );
};
