import {
  Box,
  HStack,
  IconName,
  Menu,
  MenuItem,
  MixpanelEventProperty,
  ThemeToggle,
  useAuthContext,
  useWLLayoutType,
  useWLOrgConfigContext,
  useWLUser,
  type ThemeTypes,
} from '@talos/kyoko';
import { Button, ButtonGroup, FormControlSizes, MixpanelEvent, NavButton, useMixpanel } from '@talos/kyoko/src';
import { useCallback } from 'react';
import { LayoutType } from '../../../layouts/layout';

export function UserMenu({
  showLayoutToggle,
  showThemeToggle,
  themeType,
  onChangeThemeType,
}: {
  showThemeToggle: boolean;
  showLayoutToggle: boolean;
  themeType: ThemeTypes;
  onChangeThemeType: (themeType: ThemeTypes) => void;
}) {
  const { logout } = useAuthContext();
  const user = useWLUser();
  const { layoutType, setLayoutType, tradingLayout } = useWLLayoutType();
  const { config } = useWLOrgConfigContext();

  const mixpanel = useMixpanel();

  const handleChangeLayout = useCallback(
    e => {
      mixpanel.track(MixpanelEvent.ChangeLayout, { [MixpanelEventProperty.Type]: e.target.value });
      setLayoutType(e.target.value);
    },
    [setLayoutType, mixpanel]
  );

  return (
    <Menu triggerIcon={IconName.User} triggerMinimal={false} triggerRound={false}>
      <Box px="spacingComfortable" py="spacingDefault">
        <Box color="colorTextImportant">{user.DisplayName}</Box>
        {!config.hideUserEmail && <Box fontSize="fontSizeSmall">{user.Email}</Box>}
      </Box>
      {config.menuItems?.map(item => (
        <NavButton
          ghost
          key={`${item.label}_${item.url}`}
          to={item.url}
          target="_blank"
          rel="noreferrer"
          endIcon={IconName.ExternalLink}
        >
          {item.label}
        </NavButton>
      ))}
      {showThemeToggle && (
        <HStack
          gap="spacingComfortable"
          color="colorTextImportant"
          pl="spacingComfortable"
          pr="spacingDefault"
          py="spacingDefault"
          justifyContent="space-between"
          w="100%"
        >
          Theme type <ThemeToggle themeType={themeType} onChangeThemeType={onChangeThemeType} />
        </HStack>
      )}
      {showLayoutToggle && (
        <ButtonGroup size={FormControlSizes.Small}>
          <Button
            disabled={layoutType === LayoutType.SimpleRFQLayout}
            value={LayoutType.SimpleRFQLayout}
            onClick={handleChangeLayout}
          >
            RFQ layout
          </Button>
          <Button disabled={layoutType === tradingLayout} value={tradingLayout} onClick={handleChangeLayout}>
            Trading layout
          </Button>
        </ButtonGroup>
      )}
      <MenuItem onClick={logout} endIcon={IconName.Logout}>
        Logout
      </MenuItem>
    </Menu>
  );
}
