import {
  BlotterDensity,
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type Column,
  type ColumnDef,
  type DateRangeFilter,
  type FundingEvent,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useFundingEventsFilter } from 'components/Filters/FilterBuilder/useFundingEventsFilter';
import { isEqual, pick } from 'lodash';
import { useEffect, useMemo } from 'react';

const COLUMNS: Column[] = [
  { type: 'account', field: 'MarketAccount', width: 200 },
  { type: 'currency', field: 'Asset' },
  { type: 'asset', field: 'PositionAsset' },
  { type: 'number', field: 'Amount', params: { currencyField: 'Asset' } },
  { type: 'number', field: 'Rate' },
  { type: 'date', field: 'TransactTime' },
  { type: 'text', field: 'SettleStatus' },
  { type: 'text', field: 'TransactionID', width: 250, hide: true },
] satisfies ColumnDef<FundingEvent>[];

const SEARCH_KEYS: (keyof FundingEvent)[] = ['MarketAccount', 'Asset', 'PositionAsset'];

export const useFundingEventsBlotter = () => {
  const request: WebsocketRequest = useConstant({
    name: 'FundingEvent',
    tag: 'BLOTTER_FUNDING_EVENTS',
  });

  const persistedBlotterTable = usePersistedBlotterTable('FundingEvents', {
    columns: COLUMNS,
  });

  const filteredFundingEvents = useFundingEventsFilter({ persistedBlotterTable });
  const {
    initialFilter,
    blotterTableFilterProps,
    filterBuilderProps,
    clientSideFilter: clientLocalFilter,
  } = filteredFundingEvents;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, FundingEvent>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    initialFilter: onlyServerFilterKeys(initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onFilterChanged: persistedBlotterTable.onFilterChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'TransactionID',
    animateRows: true,
    density: BlotterDensity.Comfortable,
    rowHeight: 40,
    clientLocalFilter,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
    },
  });

  const { onFilterChanged } = blotterTable;

  /**
   * When the configured filter changes tell WSBlotterTable about
   * the server keys of the filter but not any locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filteredFundingEvents.filter) {
      const serverFilter = { ...onlyServerFilterKeys(filteredFundingEvents.filter) };
      if (!isEqual(blotterTable.filter, serverFilter)) {
        onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable.filter, filteredFundingEvents.filter, onFilterChanged]);

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate', 'MarketAccounts']);
}
