import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  ButtonVariants,
  Divider,
  FormControlSizes,
  FormattedMessage,
  IconName,
  MixpanelEvent,
  WL_VIEW_BLOTTERS_WITHDRAW,
  createCSVFileName,
  useDisclosure,
  useIntl,
  useMixpanel,
  useWLOrgConfigContext,
  useWLRoleAuth,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { DepositDialog, WithdrawDialog } from '../../../components/Dialogs';
import { BlotterActions } from '../styles';
import type { TabbedBlotterProps } from '../types';
import { useTransfersBlotter } from './hooks/useTransfersBlotter';

const messages = defineMessages({
  exportTransfers: {
    defaultMessage: 'Export Transfers',
    id: 'Blotters.TransfersBlotter.exportTransfers',
  },
  deposit: {
    defaultMessage: 'Deposit',
    id: 'Blotters.TransfersBlotter.deposit',
  },
  withdraw: {
    defaultMessage: 'Withdraw',
    id: 'Blotters.TransfersBlotter.withdraw',
  },
  submitDeposit: {
    defaultMessage: 'Submit Deposit',
    id: 'Blotters.TransfersBlotter.submitDeposit',
  },
  submitWithdrawRequest: {
    defaultMessage: 'Submit Withdraw Request',
    id: 'Blotters.TransfersBlotter.submitWithdrawRequest',
  },
  recordDeposit: {
    defaultMessage: 'Record Deposit',
    id: 'Blotters.TransfersBlotter.recordDeposit',
  },
});

export type BalanceBlotterProps = Required<Pick<TabbedBlotterProps, 'hidden' | 'maxRows'>>;

export const TransfersBlotter = ({ hidden, maxRows }: BalanceBlotterProps) => {
  const { isAuthorized } = useWLRoleAuth();
  const { config } = useWLOrgConfigContext();
  const depositDialog = useDisclosure();
  const withdrawDialog = useDisclosure();
  const mixpanel = useMixpanel();
  const { formatMessage } = useIntl();

  const handleDeposit = useCallback(() => {
    depositDialog.open();
  }, [depositDialog]);

  const handleWithdraw = useCallback(() => {
    withdrawDialog.open();
  }, [withdrawDialog]);

  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useTransfersBlotter({
    maxRows,
    tag: 'TransfersBlotter',
  });

  const handleExportTransfers = useCallback(() => {
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'Transfers',
      }),
    });
    mixpanel.track(MixpanelEvent.ExportTransactions);
  }, [blotterTable, mixpanel]);

  return (
    <>
      {!hidden && (
        <BlotterTableFilters
          {...filterBuilderAccordion}
          {...blotterTableFilterProps}
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={false}
          prefix={
            config.enableBalanceTransactions &&
            isAuthorized(WL_VIEW_BLOTTERS_WITHDRAW) && (
              <BlotterActions>
                <Button size={FormControlSizes.Small} onClick={handleExportTransfers}>
                  <FormattedMessage {...messages.exportTransfers} />
                </Button>

                {!config.hideRecordDepositButton && (
                  <>
                    <Divider orientation="vertical" my="spacingSmall" />
                    <Button
                      startIcon={IconName.Download}
                      size={FormControlSizes.Small}
                      variant={ButtonVariants.Default}
                      onClick={handleDeposit}
                      data-testid="record-deposit-button"
                    >
                      <FormattedMessage {...messages.recordDeposit} />
                    </Button>
                  </>
                )}
                <Button
                  startIcon={IconName.Upload}
                  size={FormControlSizes.Small}
                  variant={ButtonVariants.Default}
                  onClick={handleWithdraw}
                >
                  <FormattedMessage {...messages.withdraw} />
                </Button>
              </BlotterActions>
            )
          }
        />
      )}
      <BlotterTable {...blotterTable!} />
      <DepositDialog
        {...depositDialog}
        title={formatMessage(messages.deposit)}
        showCancel={false}
        confirmLabel={formatMessage(messages.submitDeposit)}
        width={550}
        closeOnClickOutside={false}
        alignContent="left"
        autoFocusFirstElement={false}
        showClose={true}
      />
      <WithdrawDialog
        {...withdrawDialog}
        title={formatMessage(messages.withdraw)}
        showCancel={false}
        confirmLabel={formatMessage(messages.submitWithdrawRequest)}
        width={500}
        alignContent="left"
        autoFocusFirstElement={false}
        showClose={true}
      />
    </>
  );
};
