import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  FormControlSizes,
  FormattedMessage,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  createCSVFileName,
  useMixpanel,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { BlotterActions } from '../styles';
import type { TabbedBlotterProps } from '../types';
import { useTradesBlotter } from './hooks/useTradesBlotter';

export type TradeBlotterProps = Required<TabbedBlotterProps>;

const messages = defineMessages({
  exportTrades: {
    defaultMessage: 'Export Trades',
    id: 'Blotters.TradesBlotter.exportTrades',
  },
});

export const TradesBlotter = ({ hidden, loading, maxRows, onStartLoading, onFinishedLoading }: TradeBlotterProps) => {
  const mixpanel = useMixpanel();

  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useTradesBlotter({ maxRows });

  const handleExportTrades = useCallback(() => {
    onStartLoading();
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'Trades',
      }),
    });
    onFinishedLoading();
    mixpanel.track(MixpanelEvent.ExportTrades);
  }, [blotterTable, mixpanel, onStartLoading, onFinishedLoading]);

  return (
    <>
      {!hidden && (
        <>
          <BlotterTableFilters
            {...filterBuilderAccordion}
            {...blotterTableFilterProps}
            {...blotterTable.blotterTableFiltersProps}
            prefix={
              <BlotterActions>
                <Button size={FormControlSizes.Small} loading={loading} onClick={handleExportTrades}>
                  <FormattedMessage {...messages.exportTrades} />
                </Button>
              </BlotterActions>
            }
          />
        </>
      )}
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.TransactTime} />
      <BlotterTable {...blotterTable} />
    </>
  );
};
