import { useEffect, useMemo } from 'react';

import {
  BlotterDensity,
  WLBuildCreditExposureInstancesWithSides,
  creditExposuresCompositeIDPipe,
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type Column,
  type DateRangeFilter,
  type EnrichedCreditExposure,
  type WebsocketRequest,
} from '@talos/kyoko';
import { isEqual, pick } from 'lodash';
import { pipe } from 'rxjs';
import { useCreditExposuresFilter } from '../../../../components/Filters';

const COLUMNS: Column[] = [
  { type: 'account', field: 'MarketAccount' },
  { type: 'currency', field: 'Currency' },
  {
    type: 'size',
    field: 'shortExposure',
    width: 200,
    params: { currencyField: 'ExposureCurrency' },
  },
  {
    type: 'size',
    field: 'shortExposureLimit',
    width: 200,
    params: { currencyField: 'ExposureCurrency' },
  },
  {
    type: 'size',
    field: 'longExposure',
    width: 200,
    params: { currencyField: 'ExposureCurrency' },
  },
  {
    type: 'size',
    field: 'longExposureLimit',
    width: 200,
    params: { currencyField: 'ExposureCurrency' },
  },
];

interface UseCreditExposuresBlotterProps {
  renderEmpty: () => JSX.Element;
}

const DEFAULT_SORT = '-Exposure';
const SEARCH_KEYS: (keyof EnrichedCreditExposure)[] = [
  'MarketAccount',
  'Currency',
  'shortExposure',
  'shortExposureLimit',
  'longExposure',
  'longExposureLimit',
];

export const useCreditExposuresBlotter = ({ renderEmpty }: UseCreditExposuresBlotterProps) => {
  const request: WebsocketRequest = useConstant({
    name: 'Exposure',
    tag: 'BLOTTER_CREDIT_EXPOSURES',
    Throttle: '1s',
    sort_by: DEFAULT_SORT,
  });

  const persistedBlotterTable = usePersistedBlotterTable('creditExposures', {
    columns: COLUMNS,
    sort: DEFAULT_SORT,
  });

  const filteredTrades = useCreditExposuresFilter({ persistedBlotterTable });
  const { initialFilter, blotterTableFilterProps, filterBuilderProps } = filteredTrades;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const compositePipe = useConstant(pipe(WLBuildCreditExposureInstancesWithSides, creditExposuresCompositeIDPipe));

  const blotterTable = useWsBlotterTable<WebsocketRequest, EnrichedCreditExposure>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    initialFilter: onlyServerFilterKeys(initialFilter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onFilterChanged: persistedBlotterTable.onFilterChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'rowID',
    animateRows: true,
    flashRows: ['add'],
    density: BlotterDensity.Comfortable,
    rowHeight: 40,
    pipe: compositePipe,
    renderEmpty,
    quickSearchParams: {
      entitySearchKeys: SEARCH_KEYS,
    },
  });

  const { onFilterChanged } = blotterTable;

  /**
   * When the configured filter changes tell WSBlotterTable about
   * the server keys of the filter but not any locally evaluated keys as they might break the backend.
   */
  useEffect(() => {
    if (filteredTrades.filter) {
      const serverFilter = { ...onlyServerFilterKeys(filteredTrades.filter) };
      if (!isEqual(blotterTable.filter, serverFilter)) {
        onFilterChanged(serverFilter);
      }
    }
  }, [blotterTable.filter, filteredTrades.filter, onFilterChanged]);

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate']);
}
