/** MixpanelEvents get triggered by the mixpanel.track(...) method provided by useMixpanel() */
export enum MixpanelEvent {
  AcceptLoanQuoteRequest = 'Accept loan quote request',
  AcceptRfqQuote = 'Accept RFQ quote',
  AddCardCancel = 'Add card cancel',
  AddCardFinish = 'Add card finish',
  AddCardStart = 'Add card start',
  AddCredential = 'Add credential',
  AddMarketsTab = 'Add markets tab',
  AddMonitoringBlotterTab = 'Add monitoring blotter tab',
  AddTab = 'Add tab',
  AddTreasuryLink = 'Add treasury link',
  AdjustBlotterHeight = 'Adjust blotter height',
  AllowSyntheticCounterCurrencyRequests = 'Allow synthetic counter currency requests',
  AllowSyntheticCrosses = 'Allow synthetic crosses',
  AlwaysDisplayBestBidOffer = 'Always Display Best Bid/Offer',
  ApiOrdersToggle = 'Api orders toggle',
  ApplyDetailsToOrderComment = 'Apply details to Order comment',
  ApplyFilterBuilderFilter = 'Apply FilterBuilder filter',
  ArchiveAllOrders = 'Archive all orders',
  ArchiveCard = 'Archive card',
  ArchiveOrder = 'Archive order',
  BookManualTrade = 'Book manual trade',
  BlotterShowDividersAlways = 'Show Blotter Column Dividers Always',
  CalculateMargin = 'Calculate Margin',
  CancelAdmin = 'Cancel (admin)',
  CancelAllOrders = 'Cancel all orders',
  CancelForce = 'Cancel (force)',
  CancelLoanQuoteRequest = 'Cancel loan quote request',
  CancelOrder = 'Cancel order',
  CancelRfq = 'Cancel RFQ',
  CancelCustomerQuote = 'Cancel customer quote',
  CancelBulkOrderSubmission = 'Cancel bulk order submission',
  ChangeAllocations = 'Change allocations',
  ChangeAllowedSlippage = 'Change allowed slippage',
  ChangeAnalyticsTab = 'Change analytics tab',
  ChangeBlotterColumns = 'Change blotter columns',
  ChangeCardSlide = 'Change card slide',
  ChangeCredentialsTab = 'Change credentials tab',
  ChangeHomeCurrency = 'Change Home currency',
  ChangeLadderIncrement = 'Change ladder increment',
  ChangeLadderResolution = 'Change ladder resolution',
  ChangeLayout = 'Change layout',
  ChangeLoanQuoteRequestType = 'Change request type',
  ChangeLoanSide = 'Change loan side',
  ChangeMarketAccount = 'Change market account',
  ChangeMarketDistributionTab = 'Change market distribution tab',
  ChangeMarketTab = 'Change market tab',
  ChangeMonitoringBlotterEntity = 'Change monitoring blotter entity',
  ChangeOrderCurrency = 'Change Order currency',
  ChangeOrderDetailsTab = 'Change order details tab',
  ChangeOrderFormSymbol = 'Change order form symbol',
  ChangeOrderFormTab = 'Change order form tab',
  ChangeOrderGroup = 'Change order group',
  ChangeOrderMarketAccounts = 'Change order market accounts',
  ChangeOrderPreset = 'Change order preset',
  ChangeOrderPrice = 'Change order price',
  ChangeOrderSide = 'Change order side',
  ChangeOrderStrategy = 'Change order strategy',
  ChangePayoutChartValues = 'Change payout chart values',
  ChangePriceBucketing = 'Change price bucketing',
  ChangeQuantityToggle = 'Change quantity toggle',
  ChangeRate = 'Change rate',
  ChangeReportMarket = 'Change report market',
  ChangeReportMonth = 'Change report month',
  ChangeReportSizeBucket = 'Change report size bucket',
  ChangeReportStrategy = 'Change report strategy',
  ChangeReportSymbol = 'Change report symbol',
  ChangeReportType = 'Change report type',
  ChangeRfqCurrency = 'Change RFQ currency',
  ChangeRfqGroup = 'Change RFQ group',
  ChangeRfqMarketAccounts = 'Change RFQ market accounts',
  ChangeRfqSide = 'Change RFQ side',
  ChangeRfqSymbol = 'Change RFQ symbol',
  ChangeSettlementTime = 'Change settlement time',
  ChangeSettlementPerspective = 'Change settlement perspective',
  ChangeSidebarPin = 'Change sidebar pin',
  ChangeSizeBuckets = 'Change size buckets',
  ChangeSubAccount = 'Change subAccount',
  ChangeSymbol = 'Change symbol',
  ChangeThemeType = 'Change theme type',
  ChangeTimeFilter = 'Change time filter',
  ChangeTimelineFilter = 'Change timeline filter',
  ChangeTopOfBookSize = 'Change Top of Book size',
  ClearAllNotifications = 'Clear all notifications',
  ClickedNotification = 'Clicked notification',
  ClickCreateNewAutohedgingRule = 'Click create new autohedging rule',
  ClickLadderRow = 'Click ladder row',
  ClickOpenFilterBuilderButton = 'Click open FilterBuilder button',
  ClickReportLegend = 'Click report legend',
  ClickHeaderAutoHedgingButton = 'Click header AutoHedging button',
  ClickGlobalAutoHedgingActionButton = 'Click global AutoHedging action button',
  ClickCreateHedgeRule = 'Click create hedge rule',
  ClickUpdateHedgeRule = 'Click update hedge rule',
  CloneSubAccount = 'Clone Sub Account',
  CloneTab = 'Clone tab',
  CloseCardSettings = 'Close card settings',
  CloseMarketTabSettings = 'Close market tab settings',
  ClosePosition = 'Close position',
  CloseRfqForm = 'Close RFQ form',
  CloseTab = 'Close tab',
  CollapseAllRows = 'Collapse all rows',
  ConfirmGlobalAutohedgingCommand = 'Confirm Global Autohedging Command',
  CopyCell = 'Copy cell',
  CopyDetails = 'Copy details',
  CopyJSON = 'Copy JSON',
  CopyRowAsCSV = 'Copy row as CSV',
  CopyRowAsJSON = 'Copy row as JSON',
  CopyRowsInGroupAsCSV = 'Copy rows in group as CSV',
  CopyRowsInGroupAsJSON = 'Copy rows in group as JSON',
  CopyURL = 'Copy URL',
  CreateMarketCard = 'Create market card',
  CreateNewMultileg = 'CreateNewMultileg',
  CreatePassChangeUrl = 'Create Pass Change Url',
  CreateTempAccountLedgerEventsDetailsTab = 'Create Temporary Account Ledger Events Details Tab',
  CreateTempReconMismatchDetailsTab = 'Create Temporary Recon Mismatch Details Tab',
  CreateSubAccountClick = 'Create Sub Account Click',
  CustomerBlotterColumns = 'Customer blotter columns',
  CustomerPCApplyTotal = 'Customer Pricing Calculator Apply Total',
  CustomerPCCopyDetails = 'Customer Pricing Calculator Copy Details',
  CustomerPCLockCustomerPrice = 'Customer Pricing Calculator Lock Customer Price',
  CustomerPCResetConversionRate = 'Customer Pricing Calculator Reset Conversion Rate',
  CustomerPricingCalculatorInOrderForm = 'Customer Pricing Calculator in the Order Form',
  CustomerPricingCalculatorStartsExpanded = 'Customer Pricing Calculator starts expanded',
  DefaultStrategy = 'Default strategy',
  DeleteCard = 'Delete card',
  DisableEquityMarginRatioLimit = 'Disable Equity Margin Ratio Limit',
  DismissAllTours = 'Dismiss All Tours',
  DismissTour = 'Dismiss Tour',
  DragAndDropGrouping = 'Drag and Drop Grouping',
  EditMultileg = 'Edit multileg',
  EditUser = 'Edit user',
  EditBulkClosePositionSymbol = 'Edit Bulk Close Position Symbol',
  EnableAdvancedOrderDetails = 'Enable Advanced Order Details',
  EnableCheckLimitPriceReasonability = 'Enable Check limit price reasonability',
  EnableClickToTrade = 'Enable Click to trade',
  EnableConfirmOrderCancels = 'Enable Confirm order cancels',
  EnableConfirmOrderResume = 'Enable Confirm order resume',
  EnableDefaultingDerivativeQuantityToContracts = 'Enable defaulting initial derivative quantity in Contract terms',
  EnableEquityMarginRatioLimit = 'Enable Equity Margin Ratio Limit',
  EnableOldOrderForm = 'Use legacy order form',
  EnableOrderFilledSound = 'Enable Order filled sound',
  EnableTotalCostOnHover = 'Enable Total cost on hover',
  EnableTradingMarketAccount = 'Enable Trading - Market Account',
  ExpandAllRows = 'Expand All Rows',
  ExpandBlotter = 'Expand blotter',
  ExpandMarketCardContractDetails = 'Expand market card contract details',
  ExpandRow = 'Expand row',
  ExclusivelyPrimePriceOnRePriming = 'Do not prime qty when re-priming rate in New Order',
  ExitConfirmGlobalAutohedgingCommand = 'Exit Confirm Global Autohedging Command',
  ExportBalances = 'Export balances',
  ExportCredit = 'Export credit',
  ExportFundingEvents = 'Export Funding Events',
  ExportPositions = 'Export Positions',
  ExportLoanQuotes = 'Export loan quotes',
  ExportLoans = 'Export loans',
  ExportOpenOrders = 'Export Open Orders',
  ExportOrders = 'Export orders',
  ExportReport = 'Export report',
  ExportSettlementReport = 'Export settlement report',
  ExportRows = 'Export rows',
  ExportRowsToExcel = 'Export rows (to Excel)',
  ExportRowsModalOpen = 'Export rows modal open',
  ExportTrades = 'Export trades',
  ExportTransactions = 'Export transactions',
  FavoriteFilter = 'Favorite Filter',
  FavoriteOrderPreset = 'Favorite order preset',
  FavoriteSymbol = 'Favorite symbol',
  FilterCell = 'Filter cell',
  FilterColumn = 'Filter column',
  FilterQuickSearch = 'Filter QuickSearch',
  FilterTimeTypeChanged = 'Filter time type changed',
  FilterTransferConfigRows = 'Filter transfer config rows',
  FilterWatchlist = 'Filter watchlist',
  ForceLogout = 'Force Logout',
  GroupAccounts = 'Group accounts',
  GroupAssets = 'Group assets',
  GroupCustomer = 'Group Customer',
  GroupExpiries = 'Group expiries',
  GroupingRemoved = 'Grouping removed',
  GroupProducts = 'Group products',
  GroupProductType = 'Group Product Type',
  GroupSubAccounts = 'Group sub accounts',
  GroupUnderlying = 'Group Underlying',
  HideBlotterColumn = 'Hide blotter column',
  HoverReportLegend = 'Hover report legend',
  HoverUnderlyingPrice = 'Hover underlying price',
  HoverWarningIcon = 'Hover warning icon',
  InitiateCustomerTransfer = 'Initiate customer transfer',
  InitiateMarketplaceTransfer = 'Initiate marketplace transfer',
  InitiatePrincipalTransfer = 'Initiate principal transfer',
  InviteNewUser = 'Invite new user',
  KillSwitch = 'Kill switch',
  KnowledgeBase = 'Knowledge base',
  LoadApp = 'Load app',
  LogOut = 'Log out',
  LinkCustomerHedgeSubaccount = 'Link customer hedge subaccount',
  MarketSecurity = 'Market security',
  MarketSecurityEnableDisableDialogOpened = 'Market security enable/disable dialog opened',
  MarketTabClose = 'Market tab close',
  MarketTabDiscard = 'Market tab discard',
  MarketTabSave = 'Market tab save',
  MinimizeBlotter = 'Minimize blotter',
  ModifyManualTrade = 'Modify manual trade',
  ModifyOrder = 'Modify order',
  ModifyOrderGroup = 'Modify order group',
  ModifyOrders = 'Modify orders',
  ModifyOrphanTrade = 'Modify orphan trade',
  ModifySalesOrder = 'Modify sales order',
  ModifySubaccount = 'Modify Sub Account',
  MovedCard = 'Moved card',
  NavigateToCreditAndSettlement = 'Navigate to Credit and Settlement',
  NavigateToHedgeRulesTab = 'Navigate to Hedge Rules Tab',
  NavigateToPerformance = 'Navigate to Performance',
  NavigateToPortfolio = 'Navigate to Portfolio',
  // Start of Portfolio Management events
  NavigateToPortfolioManagementOverview = 'Portfolio Management Overview',
  NavigateToPortfolioManagementPerformance = 'Portfolio Management Performance',
  NavigateToPortfolioManagementTransactions = 'Portfolio Management Transactions',
  NavigateToPortfolioManagementReports = 'Portfolio Management Reports',
  NavigateToPortfolioRiskExposures = 'Portfolio Risk Exposures',
  NavigateToPortfolioOperationsOverview = 'Portfolio Operations Overview',
  NavigateToPortfolioOperationsTransactions = 'Portfolio Operations Transactions',
  NavigateToPortfolioOperationsSettlement = 'Portfolio Operations Settlement',
  NavigateToPortfolioOperationsReconciliation = 'Portfolio Operations Reconciliation',
  NavigateToPortfolioOperationsReports = 'Portfolio Operations Reports',
  // End of Portfolio Management events
  NavigateToPortfolioAddresses = 'Navigate to Addresses',
  NavigateToSettlement = 'Navigate to Settlement',
  NavigateToTransfers = 'Navigate to Transfers',
  NavigateToTreasuryManagement = 'Navigate to Treasury Management',
  NavigateToUsers = 'Navigate to Users page',
  NavigateToWhatsNew = 'Navigate to Whats new',
  NegotiateLoanQuoteRequest = 'Negotiate loan quote request',
  NewUser = 'New user',
  NextTourStep = 'Next tour step',
  OpenCardSettings = 'Open card settings',
  OpenManualPricingForm = 'Open manual pricing form',
  OpenCredentials = 'Open credentials',
  OpenDeepDive = 'Open DeepDive',
  OpenManualFillForm = 'Open manual fill form',
  OpenManualTradeForm = 'Open manual trade form',
  OpenMultilegDeepDive = 'OpenMultilegDeepDive',
  OpenOptionsChain = 'Open Options Chain',
  OpenOptionsColumnsSelector = 'Open options columns selector',
  OpenOrderAnalytics = 'Open order analytics',
  OpenOrderDetails = 'Open order details',
  OpenOrderForm = 'Open order form',
  OpenOrderImportForm = 'Open order import form',
  OpenOrderPresetDialog = 'Open order preset dialog',
  OpenPowerSearch = 'Open power search',
  OpenReconMismatchResolutionDrawer = 'Open Recon Mismatch Resolution Drawer',
  OpenReportTab = 'Open report tab',
  OpenResubmitOrder = 'Open resubmit order',
  OpenModifyOrder = 'Open modify order',
  OpenResubmitRemaining = 'Open resubmit remaining',
  OpenResubmitRfq = 'Open resubmit RFQ',
  OpenRfqForm = 'Open RFQ form',
  OpenSalesOrderForm = 'Open sales order form',
  OpenSalesRfqForm = 'Open Sales RFQ form',
  OpenSupportModal = 'Open Support Modal',
  OpenTradingControlsSettings = 'Open Trading Controls Settings',
  OpenTransfersDrawer = 'Open Transfers Drawer',
  OpenAutoHedgePositionRuleDrawer = 'Open Auto Hedge Position Rule Drawer',
  OpenUpdateBalancesModal = 'Open update balances modal',
  OptionToBookCustomerTrade = 'option to Book Customer Trade',
  OrderArchivedSound = 'Order archived sound',
  OrderCanceledSound = 'Order canceled sound',
  OrderGroups = 'Order groups',
  OrderRejectedSound = 'Order rejected sound',
  PauseAllOrders = 'Pause all orders',
  PauseOrder = 'Pause order',
  PinSidebar = 'Pin sidebar',
  PlaceCard = 'Place card',
  PreviewSalesOrder = 'Preview sales order',
  PrevTourStep = 'Prev tour step',
  PMSTreatStablecoinsAsCash = 'PMS: Treat Stablecoins as Cash',
  PrimeOrder = 'Prime order',
  PrimeOrderQty = 'Prime order + qty',
  PrimeSymbolSelector = 'Prime order with symbol from search dropdown',
  RaisePaginationLimit = 'Raise pagination limit',
  RejectLoanQuoteRequest = 'Reject loan quote request',
  RemoveBulkClosePositionRow = 'Remove Bulk Close Position Row',
  RemoveReportTab = 'Remove report tab',
  RemoveUser = 'Remove User',
  RenameTab = 'Rename tab',
  ReorderTab = 'Reorder tab',
  ResetAppConfig = 'Reset app config',
  ResetBlotterColumns = 'Reset blotter columns',
  ResetColumns = 'Reset columns',
  ResolveReconMismatches = 'Resolve Recon Mismatches',
  ResumeAllOrders = 'Resume all orders',
  ResumeOrder = 'Resume order',
  SalesWorkflow = 'Sales workflow',
  SaveMarketTabSettings = 'Save market tab settings',
  SaveOrderPreset = 'Save order preset',
  SendBulkClosePositionOrders = 'Send bulk close position orders',
  SearchMarketsTab = 'Search markets tab',
  SelectAllColumns = 'Select all columns',
  SelectAllRows = 'Select all rows',
  SelectFutureForm = 'Select future order form',
  SelectMultilegForm = 'Select multileg order form',
  SelectOptionForm = 'Select option order form',
  SelectOrderPreset = 'Select order preset',
  SelectPerpForm = 'Select perp order form',
  SelectSpotForm = 'Select spot order form',
  SendBulkOrder = 'Send bulk order',
  SendLoanQuoteRequest = 'Send loan quote request',
  SendOrder = 'Send order',
  SendPasswordResetEmail = 'Send Password Reset Email',
  SendRfq = 'Send RFQ',
  SetBlotterTab = 'Set blotter tab',
  SetDefaultSubAccount = 'Set default sub account',
  SetMarketCardTitle = 'Set market card title',
  SetMarketCardUnifiedLiquidity = 'Set market card unified liquidity',
  SetMarketCardView = 'Set market card view',
  SetPositionBlotterTab = 'Set position blotter tab',
  SettlementInitiated = 'Settlement initiated',
  SetTransfersBlotterTab = 'Set transfers blotter tab',
  ShowBlotterColumn = 'Show blotter column',
  ShowPortfolioManagementSystem = 'Show Portfolio Management System',
  ShowExpandedDetails = 'Show expanded details',
  ShowFailedCancelOrderModal = 'Show failed cancel order modal',
  ShowJSON = 'Show JSON',
  SortMarketsTab = 'Sort markets tab',
  SoundEffects = 'Sound effects',
  StagedOrder = 'Staged order',
  Support = 'Support',
  ToggleActiveOrders = 'Toggle active orders card',
  ToggleDetailsCard = 'Toggle details card',
  ToggleFees = 'Toggle Fees',
  ToggleFirmLiquidity = 'Toggle firm liquidity',
  ToggleMarkets = 'Toggle markets',
  ToggleReportInsights = 'Toggle report insights',
  ToggleReportSummary = 'Toggle report summary',
  ToggleShowActionRequiredOnly = 'Toggle show action required only',
  ToggleShowLoanQuoteHistory = 'Toggle show loan quote history',
  ToggleShowReviewTransfer = 'Toggle show review transfer',
  ToggleShowTotals = 'Toggle ShowTotals',
  ToggleShowZeroBalances = 'Toggle ShowZeroBalances',
  ToggleSubAccountPositionsIncludeCash = 'Toggle Sub Account Positions Blotter Include Cash',
  ToggleUnifiedPositionsIncludeCash = 'Toggle Unified Positions Include Cash',
  ToggleSubAccountPositionsBlotterHierarchical = 'Toggle Sub Account Positions Blotter Hierarchical',
  ToggleWatchlist = 'Toggle watchlist',
  TreasuryDoughnutSliceClicked = 'Treasury doughnut slice clicked',
  UpdateBalances = 'Updates balance',
  UpdateColumns = 'Update columns',
  UpdateCustomerQuote = 'Update customer quote',
  UpdateCredential = 'Update credential',
  UpdateLoanQuoteRequest = 'Update loan quote request',
  UpdateMarketAccount = 'Update market account',
  UpdateMarketCard = 'Update market card',
  UpdateMarketSelection = 'Update market selection',
  UpdateOptionFilter = 'Update option filter',
  UpdateOptionStrike = 'Update option strike',
  UpdateOrder = 'Update order',
  UpdateOrderPreset = 'Update order preset',
  UpdateTransferConfigRow = 'Update transfer config row',
  UploadFileForOrderImport = 'Upload file for order import',
  ViewAllExpiries = 'View All Expiries',
  ViewAllOrderBooks = 'View All Order Books',
  ViewCredential = 'View Credential',
  ViewDeepDive = 'View DeepDive',
  ViewMarketsTabSettings = 'View markets tab settings',
  ViewMktsTooltip = 'View Mkts tooltip',
  ViewOpenMarketOrder = 'View open market order',
  ViewParamsTooltip = 'View params tooltip',
  ViewReportTooltip = 'View report tooltip',
  ViewSection = 'View section',
  ViewSingleExpiry = 'View Single Expiry',
  ViewStrategyTooltip = 'View strategy tooltip',
  ViewTradingControl = 'View Trading Control',
  ViewTradingControlDefaultSettings = 'View Default Settings',
  ViewTimelineDiff = 'View timeline diff',
  ViewTimelineJSON = 'View timeline JSON',
  ViewTradeHistory = 'View trade history',
  // Keep values sorted
}

export enum MixpanelMetadata {
  AllowedSlippage = 'Allowed slippage',
  AlwaysDisplayBestBidOffer = 'Always Display Best Bid/Offer',
  BlotterTabCounts = 'Blotter Tab Counts',
  EnableClickToTrade = 'Enable click-to-trade',
  EnableOrderGroups = 'Enable order groups',
  EnableSyntheticCurrency = 'Enable synthetic counter ccy',
  Environment = 'Environment',
  FirmLiquidity = 'Firm Liquidity',
  LastSeen = 'Last seen',
  MaxCardColumns = 'Max card columns',
  MaxCardRows = 'Max card rows',
  MaxCards = 'Max cards',
  NumberOfCardsWithCompactView = 'Number of cards with compact view',
  NumberOfCardsWithExpandedView = 'Number of cards with expanded view',
  NumberOfCardsWithMarketsList = 'Number of cards with markets list',
  NumberOfCardsWithMiniChart = 'Number of cards with mini chart',
  NumberOfCardsWithPreset = 'Number of cards with Order Preset',
  NumberOfCardsWithPriceLadder = 'Number of cards with price ladder',
  NumberOfCardsWithVolumeLadder = 'Number of cards with volume ladder',
  NumberOfFavoriteSymbols = 'Number of Favorite Symbols',
  NumberOfOpenDeepDiveTabs = 'Number of Open DeepDive Tabs',
  NumberOfOpenMarketCards = 'Number of open Market Cards',
  NumberOfOpenMarketTabs = 'Number of open Markets Tabs',
  NumberOfOpenOptionTabs = 'Number of open Option Tabs',
  NumberOfOpenOrderDetailsTabs = 'Number of Open OrderDetails Tabs',
  NumberOfOrderPresets = 'Number of Order Presets',
  NumberOfTabs = 'Number Of tabs',
  Release = 'Release',
  ShowAllInPrices = 'Show AllIn prices',
  ShowWatchlist = 'Show Watchlist',
  UseSubaccounts = 'Use subaccounts',
  UseTradeAllocations = 'Use trade allocations',
  Version = 'Version',
}

export enum MixpanelEventProperty {
  Amount = 'amount',
  App = 'app',
  Blotter = 'blotter',
  CardType = 'cardType',
  Checked = 'checked',
  ColumnIndex = 'columnIndex',
  Column = 'column',
  Columns = 'columns',
  Comment = 'comment',
  Currency = 'currency',
  Existing = 'Existing',
  Expanded = 'expanded',
  Enabled = 'enabled',
  Filter = 'filter',
  Height = 'height',
  Hide = 'Hide',
  IncludesComment = 'includesComment',
  Intent = 'intent',
  IsMonitoringUser = 'isMonitoringUser',
  Layout = 'layout',
  Method = 'Method',
  Minimized = 'minimized',
  Path = 'path',
  Pinned = 'pinned',
  ProductType = 'productType',
  Section = 'section',
  Side = 'side',
  Size = 'size',
  Source = 'source',
  Strategy = 'strategy',
  TabIndex = 'tabIndex',
  TabLabel = 'tabLabel',
  TabLength = 'tabLength',
  TabType = 'tabType',
  Tag = 'tag',
  Type = 'type',
  Value = 'value',
  View = 'view',
  WindowHeight = 'windowHeight',
  WindowWidth = 'windowWidth',
  Title = 'Title',
  Tour = 'Tour',
  ID = 'ID',
  Perspective = 'Perspective',
}

/** MixpanelEventSource provide a context for the {@link MixpanelEvent} tracking events */
export enum MixpanelEventSource {
  AccountLedgerEventsDetails = 'Account Ledger Events Details',
  PositionAutoHedgingMiniBlotter = 'Position AutoHedging Mini Blotter',
  PositionAutoHedgingDrawer = 'Position AutoHedging Drawer',
  AllocationsBlotter = 'Allocations Blotter',
  PositionAutoHedging = 'Position Autohedging',
  AnalyticsDailyOverviewReport = 'Analytics daily overview report',
  AnalyticsMarketDetailsReport = 'Analytics market details report',
  AnalyticsMarketsOverviewReport = 'Analytics market overview report',
  AnalyticsMonthlyOverviewReport = 'Analytics monthly overview report',
  AnalyticsStrategiesOverviewReport = 'Analytics strategy overview report',
  AnalyticsSymbolDetailsReport = 'Analytics symbol details report',
  AnalyticsSymbolsOverviewReport = 'Analytics symbol overview report',
  BalancesBlotter = 'Balances Blotter',
  BidOfferButtons = 'Bid/Offer buttons',
  BuyingPowerBlotter = 'Buying Power Blotter',
  CandleSticks = 'CandleSticks',
  CareOrderForm = 'CareOrderForm',
  Credentials = 'Credentials',
  CreditBlotter = 'Credit Blotter',
  CustomerExecutionReportsBlotter = 'CustomerExecutionReportsBlotter',
  CustomerOrdersBlotter = 'CustomerOrdersBlotter',
  CustomerQuotesBlotter = 'CustomerQuotesBlotter',
  CustomerTradesBlotter = 'CustomerTradesBlotter',
  CustomerSettlementDrawer = 'Customer Settlement Drawer',
  CancelOrderDialog = 'CancelOrderDialog',
  DeepDive = 'Deepdive',
  DetailsDrawer = 'Details drawer',
  ExecutionReportsBlotter = 'ExecutionReportsBlotter',
  Header = 'Header',
  KeyboardShortcut = 'Keyboard shortcut',
  LoanBook = 'Loanbook',
  LoanDetails = 'Loan details',
  MarketCard = 'Market Card',
  MarketExecutionReportsBlotter = 'MarketExecutionReportsBlotter',
  MarketOrdersBlotter = 'MarketOrdersBlotter',
  MarketQuotesBlotter = 'MarketQuotesBlotter',
  MarketsPanel = 'Markets Panel',
  MarketsTab = 'Markets tab',
  MarketTradesBlotter = 'MarketTradesBlotter',
  ManualPricingForm = 'Manual pricing form',
  MonitoringCustomerExecutionReportsBlotter = 'Monitoring Customer Execution Reports Blotter',
  MonitoringCustomerOrdersBlotter = 'Monitoring Customer Orders Blotter',
  MonitoringCustomerQuotesBlotter = 'Monitoring Customer Quotes Blotter',
  MonitoringCustomerTradesBlotter = 'Monitoring Customer Trades Blotter',
  MonitoringExecutionReportsBlotter = 'Monitoring Execution Reports Blotter',
  MonitoringMarketCashEventsBlotter = 'Monitoring Market Cash Transactions Blotter',
  MonitoringMarketExecutionReportsBlotter = 'Monitoring Market Execution Reports Blotter',
  MonitoringMarketOrdersBlotter = 'Monitoring Market Orders Blotter',
  MonitoringMarketQuotesBlotter = 'Monitoring Market Quotes Blotter',
  MonitoringMarketTradesBlotter = 'Monitoring Market Trades Blotter',
  MonitoringOrdersBlotter = 'Monitoring Orders Blotter',
  MonitoringQuotesBlotter = 'Monitoring Quotes Blotter',
  MonitoringTradesBlotter = 'Monitoring Trades Blotter',
  MultilegComboScreen = 'Multileg Combo Screen',
  MultilegModal = 'Multileg Modal',
  MultilegSecurityMaster = 'Multileg security master',
  PriceGrid = 'Price Grid',
  NavigationSidebar = 'Navigation sidebar',
  OMS = 'OMS',
  Options = 'Options',
  OptionsChain = 'Options chain',
  OrderAnalytic = 'Order Analytic',
  OrderCard = 'Order card',
  OrderDetails = 'Order Details',
  OrderDetailsAllocationsTab = 'Order Details Allocations Tab',
  OrderDetailsExecutionReportsTab = 'Order Details Execution Reports Tab',
  OrderDetailsMarketExecutionReportsTab = 'Order Details Market Execution Reports Tab',
  OrderDetailsMarketOrdersTab = 'Order Details Market Orders Tab',
  OrderDetailsTradesTab = 'Order Details Trades Tab',
  OrderForm = 'Order form',
  OrdersBlotter = 'Orders blotter',
  Portfolio = 'Portfolio',
  // replace after PMS fully releases
  PortfolioAddresses = 'Portfolio Addresses (a.k.a Settings->Address Defaults)',
  PortfolioCreditAndSettlement = 'Portfolio Credit And Settlement',
  PortfolioPerformance = 'Portfolio Performance',
  // replace after PMS fully releases
  PortfolioSettlement = 'Portfolio Settlement (a.k.a. Dealer->Client Settlement)',
  PortfolioDashboard = 'Portfolio Dashboard',
  // Start of Portfolio Management sources
  PortfolioManagementOverview = 'Portfolio Management Overview',
  PortfolioManagementPerformance = 'Portfolio Management Performance',
  PortfolioManagementTransactions = 'Portfolio Management Transactions',
  PortfolioManagementReports = 'Portfolio Management Reports',
  PortfolioRiskExposures = 'Portfolio Risk Exposures',
  PortfolioOperationsOverview = 'Portfolio Operations Overview',
  PortfolioOperationsTransactions = 'Portfolio Operations Transactions',
  PortfolioOperationsSettlement = 'Portfolio Operations Settlement',
  PortfolioOperationsReconciliation = 'Portfolio Operations Reconciliation',
  PortfolioOperationsReports = 'Portfolio Operations Reports',
  // End of Portfolio Management sources
  PositionsBlotter = 'Positions Blotter',
  PowerSearch = 'PowerSearch',
  PriceLadder = 'Price ladder',
  QuotesBlotter = 'Quotes Blotter',
  ReconMismatchesBlotter = 'Recon Mismatches Blotter',
  ReconMismatchesDetails = 'Recon Mismatches Details',
  ReconMismatchesDetailsResolveAllButton = 'Recon Mismatches Details Resolve All Button',
  ReconMismatchesDetailsResolveOneButton = 'Recon Mismatches Details Resolve One Button',
  ReconMismatchesResolutionDrawer = 'Recon Mismatches Resolution Drawer',
  RfqCard = 'RFQ card',
  RfqForm = 'RFQ form',
  RfqQuote = 'RFQ quote',
  RfqTradeBest = 'RFQ trade best',
  SalesRfqCard = 'Sales RFQ Card',
  SecurityMaster = 'Security Master',
  SubAccountRollups = 'SubAccount Rollups',
  SubAccounts = 'SubAccounts',
  SymbolSelector = 'Symbol Selector',
  TopOfBook = 'Top of Book',
  TradeAllocationsBlotter = 'Trade Allocations Blotter',
  PositionAutoHedgingBlotter = 'Position AutoHedging Blotter',
  TradesBlotter = 'Trades blotter',
  TradingAggregations = 'Trading Aggregations',
  TradingControls = 'Trading Controls',
  TransferBlotter = 'Transfers Blotter',
  TreasuryBlotter = 'Treasury blotter',
  TreasuryManagement = 'Treasury Management',
  Users = 'Users',
  VolumeLadder = 'Volume ladder',
  Watchlist = 'Watchlist',
}
