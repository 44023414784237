import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  ResizerPlacement,
  movementAndPlacementToSizeChange,
  useElementSize,
  useEventListener,
  type SizeProps,
} from '@talos/kyoko';
import Big from 'big.js';
import { range as getRange } from 'lodash';
import { flushSync } from 'react-dom';
import type { LayoutComponent, LayoutPart } from '../../layouts/types';

export function useResizableLayout(layout: LayoutComponent, spacingLayout: number) {
  const [columns, setColumns] = useState(layout.columns.join(' '));
  const [rows, setRows] = useState(layout.rows.join(' '));
  const [mouseDown, setMouseDown] = useState(false);
  const resizingElementRef = useRef<{ element?: HTMLElement; part: LayoutPart }>();
  const prevTouch = useRef<Touch>();
  const { elementRef: partsWrapperRef, size: partsWrapperSize } = useElementSize<HTMLDivElement>();

  useEffect(() => {
    setColumns(layout.columns.join(' '));
    setRows(layout.rows.join(' '));
  }, [layout]);

  const handleMouseDown = useCallback((e: React.MouseEvent | React.TouchEvent, part: LayoutPart) => {
    // Track mouse or touch started
    setMouseDown(true);

    resizingElementRef.current = {
      part,
      element: e.currentTarget.parentElement ?? undefined,
    };
    if (e.type === 'mousedown') {
      e.preventDefault();
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      // Check if mouse button is still pressed
      if (e instanceof MouseEvent && (e as MouseEvent).buttons === 0) {
        setMouseDown(false);
        resizingElementRef.current = undefined;
      } else if (mouseDown && resizingElementRef.current != null) {
        const currentElement = resizingElementRef.current.element;
        const currentPart = resizingElementRef.current.part;
        if (currentElement != null) {
          if (currentPart.resizable === ResizerPlacement.TOP || currentPart.resizable === ResizerPlacement.BOTTOM) {
            handleResizeRows(
              e,
              prevTouch,
              partsWrapperSize,
              currentElement.offsetHeight,
              currentPart,
              spacingLayout,
              setRows
            );
          } else if (
            currentPart.resizable === ResizerPlacement.LEFT ||
            currentPart.resizable === ResizerPlacement.RIGHT
          ) {
            handleResizeColumns(
              e,
              prevTouch,
              partsWrapperSize,
              currentElement.offsetWidth,
              currentPart,
              spacingLayout,
              setColumns
            );
          }
        }
      }
    },
    [prevTouch, partsWrapperSize, mouseDown, spacingLayout]
  );

  // Handle both mouse and touch events
  useEventListener<React.MouseEvent>('mousemove', handleMouseMove);
  useEventListener<React.TouchEvent>('touchmove', handleMouseMove);
  useEventListener<React.MouseEvent>('mouseup', (e: React.MouseEvent) => setMouseDown(false));
  useEventListener<React.TouchEvent>('touchend', (e: React.TouchEvent) => setMouseDown(false));

  return { partsWrapperRef, columns, rows, mouseDown, handleMouseDown };
}

export function handleResizeColumns(
  e: MouseOrTouchEvent,
  prevTouch: React.MutableRefObject<Touch | undefined>,
  size: SizeProps,
  offsetWidth: number,
  currentPart: LayoutPart,
  spacingLayout: number,
  setColumns: React.Dispatch<React.SetStateAction<string>>
) {
  let widthChange = 0;
  if (isTouchEvent(e)) {
    // touch event does not have movementX/Y it needs to be calculated
    const touch = e.touches![0];
    widthChange = movementAndPlacementToSizeChange(
      // Calculate diff in position between previous and current event
      touch.pageX - (prevTouch.current?.pageX ?? touch.pageX),
      currentPart.resizable ?? ResizerPlacement.LEFT
    );
    prevTouch.current = touch;
  } else if (isMouseEvent(e)) {
    widthChange = movementAndPlacementToSizeChange(e.movementX!, currentPart.resizable ?? ResizerPlacement.LEFT);
  }

  handleResize(
    currentPart.column,
    widthChange,
    offsetWidth,
    spacingLayout,
    setColumns,
    currentPart.minWidth,
    currentPart.maxWidth,
    size.scrollWidth,
    size.offsetWidth
  );
}

export function handleResizeRows(
  e: MouseOrTouchEvent,
  prevTouch: React.MutableRefObject<Touch | undefined>,
  size: SizeProps,
  offsetHeight: number,
  currentPart: LayoutPart,
  spacingLayout: number,
  setRows: React.Dispatch<React.SetStateAction<string>>
) {
  let heightChange = 0;
  if (isTouchEvent(e)) {
    const touch = e.touches![0];
    heightChange = movementAndPlacementToSizeChange(
      // Calculate diff in position between previous and current event
      touch.pageY - (prevTouch.current?.pageY ?? touch.pageY),
      currentPart.resizable ?? ResizerPlacement.TOP
    );
    prevTouch.current = touch;
  } else if (isMouseEvent(e)) {
    heightChange = movementAndPlacementToSizeChange(e.movementY!, currentPart.resizable ?? ResizerPlacement.TOP);
  }

  handleResize(
    currentPart.row,
    heightChange,
    offsetHeight,
    spacingLayout,
    setRows,
    currentPart.minHeight,
    currentPart.maxHeight,
    size.scrollHeight,
    size.offsetHeight
  );
}

function handleResize(
  property: string,
  sizeChange: number,
  offsetSize: number,
  spacingLayout: number,
  setProperty,
  minSize?: number,
  maxSize?: number,
  layoutScrollSize?: number,
  layoutOffsetSize?: number
) {
  flushSync(() => {
    if (property.includes('/')) {
      // Multiple columns/rows
      // distribute change across affected columns/rows
      const [startIndex, endIndex] = property.split('/');
      const range = Big(endIndex).minus(startIndex).toNumber();
      const indexes = getRange(parseInt(startIndex) - 1, parseInt(endIndex) - 1);

      setProperty((prev: string) => {
        const span = prev.split(' ');

        if (layoutWillOverflow(sizeChange, layoutScrollSize, layoutOffsetSize)) {
          // Calculate new size, capped by maxSize and minSize
          const newSize = calculateNewSize(offsetSize, sizeChange, minSize, maxSize);
          indexes.forEach(index => {
            // Add each column share of size change
            span[index] = `${(newSize - spacingLayout * (range - 1)) / range}px`;
          });
        }
        return span.join(' ');
      });
    } else {
      // Single column/row
      const index = parseInt(property) - 1;
      setProperty((prev: string) => {
        const span = prev.split(' ');
        if (sizeChange > 0 && (layoutScrollSize ?? 0) > (layoutOffsetSize ?? 0)) {
          // Prevent change if size will overflow
          span[index] = `${offsetSize}px`;
        } else {
          // Calculate new size, capped by maxSize and minSize
          const newSize = calculateNewSize(offsetSize, sizeChange, minSize, maxSize);
          span[index] = `${newSize}px`;
        }
        return span.join(' ');
      });
    }
  });
}

const isTouchEvent = (e: MouseOrTouchEvent) => e.touches != null && e.touches.length === 1;

const isMouseEvent = (e: MouseOrTouchEvent) => e.movementX != null || e.movementY != null;

const calculateNewSize = (offsetSize: number, sizeChange: number, minSize?: number, maxSize?: number) =>
  Math.min(Math.max((offsetSize ?? 0) + sizeChange, minSize ?? 0), maxSize ?? Number.MAX_VALUE);

const layoutWillOverflow = (sizeChange: number, layoutScrollSize?: number, layoutOffsetSize?: number) =>
  sizeChange < 0 || (layoutScrollSize ?? 0) <= (layoutOffsetSize ?? 0);
export interface MouseOrTouchEvent {
  movementX?: number;
  movementY?: number;
  touches?: React.TouchList;
}

export interface Touch {
  pageX: number;
  pageY: number;
}
