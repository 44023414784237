import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDynamicCallback } from '../hooks';
import { themes, type ThemeTypes } from '../styles';
import { CustomerUserConfigLayoutType as LayoutType } from '../types';
import type { WLCustomerUserConfig } from '../types/WLCustomerUserConfig';
import { EMPTY_ARRAY, tryParseJSON } from '../utils';
import { useWLOrderFormDispatch, useWLOrderFormSelector } from './WLOrderForm/WLOrderFormStore';
import { setSymbol } from './WLOrderForm/state/OrderSlice/reducer';
import { selectSymbol } from './WLOrderForm/state/OrderSlice/selector';
import { useWLOrgConfigContext } from './WLOrgConfigProvider';
import { useWLUser } from './WLUserContextProvider';
import { useSocketClient } from './WebSocketClientProvider';

export const WLCustomerUserConfigContext = createContext<WLCustomerUserConfigContextProps | undefined>(undefined);

export function useWLCustomerUserConfigContext() {
  const context = useContext(WLCustomerUserConfigContext);
  if (context === undefined) {
    throw new Error('Missing WLConfigContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const USER_CONFIG = 'UserConfig';

export function WLCustomerUserConfigContextProvider({ children }) {
  const client = useSocketClient();
  const [config, setConfig] = useState<WLCustomerUserConfig | null>(null);
  const user = useWLUser();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (user != null && config == null) {
      setConfig({
        symbol: 'BTC-USD', // decent fallback
        ...user.Config?.reduce((config, { Key, Value }) => ({ ...config, [Key]: tryParseJSON(Value) }), {}),
      });
    }
  }, [user, config]);

  // Only set is loaded to true when user AND config is ready.
  useEffect(() => {
    if (user != null && config != null) {
      setIsLoaded(true);
    }
  }, [user, config]);

  const updateConfig = useCallback(
    (config: Partial<WLCustomerUserConfig>) => {
      if (client != null && config != null) {
        setConfig(prev => ({ ...prev!, ...config }));
        const data = Object.entries(config).map(([Key, Value]) => ({ Key, Value: JSON.stringify(Value) }));
        client.registerPublication({
          type: USER_CONFIG,
          data,
        });
      }
    },
    [client]
  );

  const value = useMemo(() => ({ config, updateConfig, isLoaded }), [config, updateConfig, isLoaded]);

  // We can't do this check on ProvidersGate, because IntlProvider appears before ProvidersGate.
  if (value.config == null) {
    return null;
  }

  return (
    <WLCustomerUserConfigContext.Provider value={value as WLCustomerUserConfigContextProps}>
      {children}
    </WLCustomerUserConfigContext.Provider>
  );
}

type WLCustomerUserConfigContextProps = {
  config: WLCustomerUserConfig;
  updateConfig: (config: Partial<WLCustomerUserConfig>) => void;
  isLoaded: boolean;
};

export const useWLPriceLadderConfig = () => {
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();
  const priceLadderConfig = config?.priceLadderConfig;
  const priceLadderSymbolConfig = priceLadderConfig != null ? priceLadderConfig[config?.symbol as string] : undefined;

  const setPriceLadderSymbolConfig = useCallback(
    (symbol, symbolConfig) =>
      updateConfig({ priceLadderConfig: { ...(priceLadderConfig ?? {}), [symbol]: symbolConfig } }),
    [updateConfig, priceLadderConfig]
  );

  return useMemo(
    () => ({ priceLadderSymbolConfig, setPriceLadderSymbolConfig, isLoaded }),
    [priceLadderSymbolConfig, setPriceLadderSymbolConfig, isLoaded]
  );
};

export const useWLMarketDataCardsConfig = () => {
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();
  const marketDataCardsConfig = config?.marketDataCardsConfig ?? EMPTY_ARRAY;

  const setMarketDataCardsConfig = useCallback(
    newMarketDataCardsConfig => {
      updateConfig({ marketDataCardsConfig: newMarketDataCardsConfig });
    },
    [updateConfig]
  );

  return useMemo(
    () => ({ marketDataCardsConfig, setMarketDataCardsConfig, isLoaded }),
    [marketDataCardsConfig, setMarketDataCardsConfig, isLoaded]
  );
};

export const useWLLayoutArrangement = () => {
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();
  const layoutArrangement: string = config?.layoutArrangement as string;

  const setLayoutArrangement = useCallback(
    (layoutArrangement: string) => updateConfig({ layoutArrangement }),
    [updateConfig]
  );

  return useMemo(
    () => ({ layoutArrangement, setLayoutArrangement, isLoaded }),
    [layoutArrangement, setLayoutArrangement, isLoaded]
  );
};

export const useWLSymbol = () => {
  const { updateConfig, isLoaded } = useWLCustomerUserConfigContext();
  const dispatch = useWLOrderFormDispatch();
  const symbol = useWLOrderFormSelector(selectSymbol);

  const updateSymbol = useDynamicCallback((newSymbol: string) => {
    updateConfig({ symbol: newSymbol });
    dispatch(setSymbol(newSymbol));
  });

  return useMemo(() => ({ symbol, setSymbol: updateSymbol, isLoaded }), [symbol, updateSymbol, isLoaded]);
};

export const useWLLayoutType = () => {
  const { config, updateConfig, isLoaded: isConfigLoaded } = useWLCustomerUserConfigContext();
  const { config: orgConfig, isLoaded: isCustomerConfigLoaded } = useWLOrgConfigContext();

  const isLoaded = isConfigLoaded && isCustomerConfigLoaded;
  const tradingLayout = orgConfig?.layout === LayoutType.SimpleRFQLayout ? LayoutType.TradingLayout : orgConfig?.layout;

  const availableLayouts = [tradingLayout, LayoutType.SimpleRFQLayout];
  // Only allow the layout in config or one of the two default layouts
  const layoutType =
    config?.layoutType != null && availableLayouts.includes(config.layoutType) ? config.layoutType : orgConfig.layout;

  const setLayoutType = useCallback(
    (layoutType: LayoutType) => {
      updateConfig({ layoutType });
    },
    [updateConfig]
  );
  return useMemo(
    () => ({ layoutType, setLayoutType, tradingLayout, isLoaded }),
    [layoutType, setLayoutType, tradingLayout, isLoaded]
  );
};

export const useWLThemeType = () => {
  const { config: customerConfig } = useWLOrgConfigContext();
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();
  const themeType =
    customerConfig.allowThemeChange !== false ? config?.themeType : (themes[customerConfig.theme].type as ThemeTypes);

  const setThemeType = useCallback((themeType: ThemeTypes) => updateConfig({ themeType }), [updateConfig]);

  return useMemo(() => ({ themeType, setThemeType, isLoaded }), [themeType, setThemeType, isLoaded]);
};

export const useWLHomeCurrency = () => {
  const { config, updateConfig, isLoaded: isConfigLoaded } = useWLCustomerUserConfigContext();
  const { config: customerConfig, isLoaded: isCustomerConfigLoaded } = useWLOrgConfigContext();
  const homeCurrency: string = config?.homeCurrency ?? customerConfig?.homeCurrency ?? 'USD';

  const isLoaded = isConfigLoaded && isCustomerConfigLoaded;

  const setHomeCurrency = useCallback((homeCurrency: string) => updateConfig({ homeCurrency }), [updateConfig]);

  return useMemo(() => ({ homeCurrency, setHomeCurrency, isLoaded }), [homeCurrency, setHomeCurrency, isLoaded]);
};

export const useWLPinSidebar = () => {
  const { config, updateConfig } = useWLCustomerUserConfigContext();

  const pinSidebar = config?.pinSidebar;

  const setPinSidebar = useCallback((pinSidebar: boolean) => updateConfig({ pinSidebar }), [updateConfig]);

  return useMemo(() => ({ pinSidebar, setPinSidebar }), [pinSidebar, setPinSidebar]);
};

export const useWLDefaultMarketAccount = () => {
  const { config, updateConfig } = useWLCustomerUserConfigContext();

  const defaultMarketAccount = config?.defaultMarketAccount;

  const setDefaultMarketAccount = useCallback(
    (defaultMarketAccount: string) => updateConfig({ defaultMarketAccount }),
    [updateConfig]
  );

  return useMemo(
    () => ({ defaultMarketAccount, setDefaultMarketAccount }),
    [defaultMarketAccount, setDefaultMarketAccount]
  );
};
