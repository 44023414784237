import type { AgGridEvent } from 'ag-grid-community';
import type { GridApi } from 'ag-grid-enterprise';
import { useEffect } from 'react';
import { iif, of } from 'rxjs';
import type { Sparkline } from '../../../contexts';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { useNamedState } from '../../../hooks/useNamedState';
import { useObservableValue } from '../../../hooks/useObservable';
import { useSparklinesData } from '../../../hooks/useSparklineData';
import { useWatchlistVisibility } from '../../../providers';
import { EMPTY_ARRAY } from '../../../utils';
import type { WatchListColumnTypes, WatchListRowData, WatchListRowNode } from '../types';
import { useRenderedRows } from './useRenderedRows';

const REQUIRED_COLUMNS: WatchListColumnTypes[] = ['sparkline', 'volume', 'refRate', 'security'];

/**
 * A hook to subscribe to Sparkline data only for the rows that are currently rendered on a grid.
 * Grid row data will be updated when the sparkline data is updated, and then an async update will
 * be triggered on those rows that were updated.
 *
 * @param gridApi Api object for the grid this hook belongs to
 */
export function useDynamicSparklineSubscription(
  gridApi: GridApi | null,
  enabledColumns: { type: WatchListColumnTypes }[]
) {
  const [renderedRows, setRenderedRows] = useNamedState<string[]>([], 'renderedRows');
  const loadDataForRenderedRows = useDynamicCallback((_event: AgGridEvent<unknown>, renderedRowIds: string[]) => {
    setRenderedRows(enabledColumns.some(c => REQUIRED_COLUMNS.includes(c.type)) ? renderedRowIds : EMPTY_ARRAY);
  });
  const sparklinesObservable = useSparklinesData(renderedRows);

  const isWatchlistVisible = useWatchlistVisibility();
  // Subscribe to sparklines only when the watchlist is visible, due to the nature of useObservableValue, the
  // subscription will be automatically unsubscribed when the watchlist is hidden.
  const latestSparklines = useObservableValue(
    () => iif(() => isWatchlistVisible, sparklinesObservable, of(new Map<string, Sparkline | undefined>())),
    [sparklinesObservable, isWatchlistVisible],
    new Map()
  );

  useEffect(() => {
    const updates: WatchListRowData[] = [];
    for (const [symbol, sparkline] of latestSparklines) {
      const gridRow = gridApi?.getRowNode(symbol) as WatchListRowNode | undefined;
      if (!gridRow) {
        continue;
      }

      gridRow.data.volume = sparkline ? sparkline.Volume ?? null : undefined;
      gridRow.data.sparklineData = sparkline;
      updates.push(gridRow.data);
    }
    if (updates.length) {
      gridApi?.applyTransactionAsync({ update: updates });
    }
  }, [gridApi, latestSparklines]);

  useRenderedRows(gridApi, loadDataForRenderedRows);
}
