import { isEqual, keys, values } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import {
  FilterClauseType,
  IconName,
  OrdStatusEnum,
  cleanupInitialFilterDateRange,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useIntl,
  useSecuritiesContext,
  useSidesFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerOrder,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';

import { defineMessages } from 'react-intl';
import { useCustomerMarketAccountsFilter, useStrategiesFilter } from './filters';

const messages = defineMessages({
  status: {
    defaultMessage: 'Status',
    id: 'Filters.FilterBuilder.status',
  },
  symbol: {
    defaultMessage: 'Symbol',
    id: 'Filters.FilterBuilder.symbol',
  },
});

export interface OrdersTableFilter extends DateRangeFilter {
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
  Strategies?: string[];
  /** These are the order status texts we are filtering by on the client side */
  _statuses?: string[];
  /* These are the order statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
}

interface UseOrdersFilterParams<TData extends CustomerOrder> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  validStatuses?: OrdStatusEnum[];
}

export function useOrdersFilter<TData extends CustomerOrder>({
  persistedBlotterTable,
  validStatuses,
}: UseOrdersFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;

  const [initialFilter] = useState<OrdersTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );
  const [filter, setFilter] = useState<OrdersTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const prevFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(prevFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Statuses', data, 'OrdStatus')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Strategies', data, 'Strategy')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();
  const customerMarketAccountsFilter = useCustomerMarketAccountsFilter();
  const strategiesFilter = useStrategiesFilter();
  const sidesFilter = useSidesFilter();
  const { formatMessage } = useIntl();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        customerMarketAccountsFilter,
        sidesFilter,
        {
          key: 'Statuses',
          label: formatMessage(messages.status),
          icon: IconName.CheckCircle,
          options: validStatuses ?? values(OrdStatusEnum),
          getOptionLabel: (option: string) => option,
        },
        strategiesFilter,
        {
          key: 'Symbols',
          label: formatMessage(messages.symbol),
          icon: IconName.CurrencyDollar,
          options: securitiesList.map(sec => sec.Symbol),
          getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
        },
      ].compact(),
    [
      customerMarketAccountsFilter,
      formatMessage,
      securitiesBySymbol,
      securitiesList,
      sidesFilter,
      strategiesFilter,
      validStatuses,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof OrdersTableFilter)[]).forEach((key: keyof OrdersTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: OrdersTableFilter = removeEmptyFilters<OrdersTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as OrdersTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return useMemo(
    () => ({
      initialFilter,
      clientSideFilter,
      filter,
      // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
      filterBuilderProps,
      // shortcut to spread props into the BlotterTableFilters component
      blotterTableFilterProps,
    }),
    [blotterTableFilterProps, clientSideFilter, filter, filterBuilderProps, initialFilter]
  );
}
