import {
  Get,
  SparklineContext,
  formattedDateForSubscription,
  isErrorResponse,
  urlFromLocalOrAbsolute,
  useDynamicCallback,
  useEndpointsContext,
  type ErrorResponse,
  type OHLCData,
  type RestResponse,
  type Sparkline,
  type SparklineContextProps,
} from '@talos/kyoko';
import Big from 'big.js';
import { sub } from 'date-fns';
import { isNil, sumBy } from 'lodash';
import { memo, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';

const sparklinesBySymbol = new BehaviorSubject(new Map<string, Sparkline | undefined>());

export const SparklineProvider = memo(function SparklineProvider({ children }: React.PropsWithChildren<unknown>) {
  const { apiEndpoint } = useEndpointsContext();
  const registerSubscription = useDynamicCallback((symbol: string) => {
    getOHCLVdata(symbol)
      .then(ohclvCallback(symbol))
      .catch((e: ErrorResponse) => {
        // No OHLCV data available for this symbol
      });
  });
  const registerSubscriptions = useDynamicCallback((symbols: string[]) => {
    symbols.forEach(symbol => {
      getOHCLVdata(symbol)
        .then(ohclvCallback(symbol))
        .catch((e: ErrorResponse) => {
          // No OHLCV data available for this symbol
        });
    });
  });

  const unregisterSubscription = useDynamicCallback((symbol: string) => {});

  const unregisterSubscriptions = useDynamicCallback((symbols: string[]) => {});

  const { endDate, startDate } = useMemo(
    () => ({ startDate: sub(new Date(), { hours: 24 }), endDate: new Date() }),
    []
  );

  const getOHCLVdata = (symbol: string, resolution = '1h') => {
    const sd = formattedDateForSubscription(startDate);
    const ed = formattedDateForSubscription(endDate);

    return Get<RestResponse<OHLCData>>(
      urlFromLocalOrAbsolute(apiEndpoint),
      `/symbols/${symbol}/ohlcv/${resolution}?startDate=${sd}&endDate=${ed}`
    );
  };

  const value = useMemo<SparklineContextProps>(
    () => ({
      sparklinesBySymbol,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
  return <SparklineContext.Provider value={value}>{children}</SparklineContext.Provider>;
});

function ohclvCallback(
  symbol: string
): ((value: RestResponse<OHLCData>) => void | PromiseLike<void>) | null | undefined {
  return res => {
    if (isErrorResponse(res)) {
      return;
    }
    const data = res.data;
    const volume = sumBy(data, d => parseFloat(d.Volume));
    const open = data?.at(0)?.Open;
    const close = data?.at(-1)?.Close;
    const sparklineData: Sparkline = {
      Open: isNil(open) ? undefined : Big(open),
      Close: isNil(close) ? undefined : Big(close),
      Volume: isNil(volume) ? undefined : Big(volume),
      Values: data?.map(d => parseFloat(d.Close)),
      PercentChange: undefined,
    };

    sparklineData.PercentChange =
      isNil(sparklineData.Close) || isNil(sparklineData.Open) || sparklineData.Open?.eq(0)
        ? undefined
        : Big(sparklineData.Close).sub(sparklineData.Open).div(sparklineData.Open).times(100);

    sparklinesBySymbol.value.set(symbol, sparklineData);
    sparklinesBySymbol.next(sparklinesBySymbol.value);
  };
}
