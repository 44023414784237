import type { Observable } from 'rxjs';

import { useElementSize } from '../../hooks';
import type { MarketDataSnapshot } from '../../types/MarketDataSnapshot';
import type { OHLCData } from '../../types/OHLCData';
import type { ResolutionEnumNoPrefix } from '../../types/OrderAnalytic';
import type { Security } from '../../types/Security';
import { SmartTruncateEnum } from '../FormattedNumber';
import { MarketView } from './MarketView';
import { Settings } from './Settings';
import { VolumeLadderHeader } from './VolumeLadderHeader';
import { VolumeLadderSteps } from './VolumeLadderSteps';
import { VolumeLadderWrapper } from './styles';
import type { ClickRowCallback } from './types';

const HEIGHT_LIMIT = 320;
const WIDTH_LIMIT = 320;
const LADDER_TOP_HEIGHT = 194;

export const VolumeLadder = ({
  resolution,
  marketViewObservable,
  marketDataObservable,
  sparklineDataObservable,
  security,
  sizeBuckets,
  showSettings,
  showSpread,
  smartTruncate = SmartTruncateEnum.None,
  errors,
  disabledSymbolSelector,
  setErrors,
  onSaveSettings,
  onSettingsClick,
  onClickRow,
  onSymbolChange,
  onChangeResolution,
  onChangeSizeBuckets,
  onResetSizeBuckets,
  bpsIncrement,
}: VolumeLadderProps) => {
  const { size, elementRef } = useElementSize<HTMLDivElement>();

  return (
    <VolumeLadderWrapper ref={elementRef}>
      <VolumeLadderHeader
        errors={errors}
        showSettings={showSettings}
        symbol={security?.Symbol}
        onSaveSettings={onSaveSettings}
        onSymbolChange={onSymbolChange}
        onSettingsClick={onSettingsClick}
        disabledSymbolSelector={disabledSymbolSelector}
        isCompact={(size.offsetHeight ?? 0) < HEIGHT_LIMIT}
        isNarrow={(size.offsetWidth ?? 0) < WIDTH_LIMIT}
      />
      <MarketView
        resolution={resolution}
        onClickRow={onClickRow}
        security={security}
        marketDataObservable={marketViewObservable}
        sparklineDataObservable={sparklineDataObservable}
        showSpread={showSpread}
        bpsIncrement={bpsIncrement}
        width={size.offsetWidth}
      />
      <VolumeLadderSteps
        sizeBuckets={sizeBuckets}
        marketDataObservable={marketDataObservable}
        security={security}
        onClickRow={onClickRow}
        showSpread={showSpread}
        smartTruncate={smartTruncate}
        width={size.offsetWidth}
        height={size.offsetHeight && size.offsetHeight - LADDER_TOP_HEIGHT}
        bpsIncrement={bpsIncrement}
      />
      {showSettings && security != null && (
        <Settings
          showSettings={showSettings}
          resolution={resolution}
          sizeBuckets={sizeBuckets}
          onChangeResolution={onChangeResolution}
          onChangeSizeBuckets={onChangeSizeBuckets}
          onResetSizeBuckets={onResetSizeBuckets}
          security={security}
          errors={errors}
          setErrors={setErrors}
        />
      )}
    </VolumeLadderWrapper>
  );
};

export type VolumeLadderProps = {
  resolution: ResolutionEnumNoPrefix;
  sparklineDataObservable: Observable<OHLCData[]>;
  marketViewObservable: Observable<MarketDataSnapshot>;
  marketDataObservable: Observable<MarketDataSnapshot>;
  security?: Security;
  sizeBuckets: string[];
  showSettings: boolean;
  showSpread?: boolean;
  smartTruncate?: SmartTruncateEnum;
  errors: boolean[];
  disabledSymbolSelector?: boolean;
  setErrors(errors: boolean[]): void;
  onSettingsClick(): void;
  onSaveSettings(): void;
  onClickRow: ClickRowCallback;
  onSymbolChange: (symbol?: string) => void;
  onChangeResolution: (resolution: ResolutionEnumNoPrefix) => void;
  onChangeSizeBuckets: (sizeBuckets: string[]) => void;
  onResetSizeBuckets: () => void;
  bpsIncrement?: string;
};
