import { useEffect } from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import { useSparklineContext, type Sparkline } from '../contexts';
import { useObservable } from './useObservable';

/**
 * Hook to subscribe to the latest sparkline data for a security.
 *
 * @param security Security name to subscribe to
 * @returns Observable of Sparkline data for the given symbol
 */
export function useSparklineData(security: string) {
  const { sparklinesBySymbol, registerSubscription, unregisterSubscription } = useSparklineContext();

  useEffect(() => {
    registerSubscription(security);
    return () => {
      unregisterSubscription(security);
    };
  }, [registerSubscription, security, unregisterSubscription]);

  return useObservable<Sparkline | undefined>(
    () => sparklinesBySymbol.pipe(map((rates: Map<string, Sparkline | undefined>) => rates.get(security))),
    [sparklinesBySymbol, security]
  );
}
/**
 * Hook to subscribe to the latest sparkline data for multiple securities.
 *
 * @param securities Array of security names to subscribe for
 * @returns Observable map of SecurityName => Sparkline Data
 */
export function useSparklinesData(securities: string[]) {
  const { sparklinesBySymbol, registerSubscriptions, unregisterSubscriptions } = useSparklineContext();

  useEffect(() => {
    registerSubscriptions(securities);
    return () => {
      unregisterSubscriptions(securities);
    };
  }, [registerSubscriptions, securities, unregisterSubscriptions]);

  const sparklinesObservable = useObservable<Map<string, Sparkline | undefined>>(
    () =>
      sparklinesBySymbol.pipe(
        map(rates => new Map(securities.map((symbol, index) => [symbol, rates.get(symbol)]))),
        distinctUntilChanged()
      ),
    [sparklinesBySymbol, securities]
  );
  return sparklinesObservable;
}
