import {
  ADDRESS,
  BlotterDensity,
  BlotterTable,
  Box,
  Button,
  FormControlSizes,
  FormattedMessage,
  HStack,
  IconName,
  Panel,
  PanelContent,
  PanelHeader,
  Text,
  useConstant,
  useDynamicCallback,
  useIntl,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type Column,
  type ColumnDef,
  type ICustomerRoutingInfo,
  type WebsocketRequest,
} from '@talos/kyoko';
import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useCustomerDepositWithdrawContext, type CustomerAddressWhitelabel } from '../../providers';

const BLOTTER_ID = 'whitelabel-addresses';

const messages = defineMessages({
  addresses: {
    defaultMessage: 'Addresses',
    id: 'Addresses.addresses',
  },
  reveal: {
    defaultMessage: 'Reveal',
    id: 'Addresses.reveal',
  },
  noWalletAddress: {
    defaultMessage: 'No Wallet Address',
    id: 'Addresses.noWalletAddress',
  },
  noMemo: {
    defaultMessage: 'No Memo',
    id: 'Addresses.noMemo',
  },
  noDestinationTag: {
    defaultMessage: 'No Destination Tag',
    id: 'Addresses.noDestinationTag',
  },
  pleaseAwaitConfirmation: {
    defaultMessage:
      'Please always send a test and await confirmation if it is your first time sending to a specific address. We are not responsible for a transfer of assets to the wrong address.',
    id: 'Addresses.pleaseAwaitConfirmation',
  },
});

export function Addresses() {
  const request: WebsocketRequest = useConstant({
    name: ADDRESS,
    tag: 'Addresses',
  });
  const { formatMessage } = useIntl();

  const [routingInfoMap] = useState<Map<string, ICustomerRoutingInfo>>(new Map());
  const { decryptCustomerAddress } = useCustomerDepositWithdrawContext();
  const RevealRoutingInfoRenderer = useDynamicCallback(
    ({ data, value, api }: ICellRendererParams<CustomerAddressWhitelabel>) => {
      if (value != null || data == null) {
        return value;
      }

      const handleOnReveal = () => {
        decryptCustomerAddress(data.AddressID).then(res => {
          const firstRoutingInfo = res.data.at(0)?.RoutingInfo;
          routingInfoMap.set(data.AddressID, {
            WalletAddress: firstRoutingInfo?.WalletAddress ?? `* ${formatMessage(messages.noWalletAddress)} *`,
            Memo: firstRoutingInfo?.Memo ?? `* ${formatMessage(messages.noMemo)} *`,
            DestinationTag: firstRoutingInfo?.DestinationTag ?? `* ${formatMessage(messages.noDestinationTag)} *`,
          });
          api.refreshCells({ columns: ['WalletAddress', 'Memo'] });
        });
      };

      return (
        <Button startIcon={IconName.EyeShow} size={FormControlSizes.Small} onClick={handleOnReveal}>
          <FormattedMessage {...messages.reveal} />
        </Button>
      );
    }
  );

  const columns = useMemo<Column[]>(
    () =>
      [
        { type: 'date', field: 'Timestamp', sortable: true, sort: '-' },
        { type: 'text', field: 'Name', sortable: true },
        { type: 'currency', field: 'Currency', sortable: true },
        { type: 'transactionType', field: 'AddressType', width: 150, sortable: true },
        { type: 'text', field: 'AddressRoutingType', sortable: true },
        { type: 'text', field: 'AddressID', sortable: true },
        { type: 'text', field: 'MarketAccount', sortable: true, hide: true },
        {
          id: 'WalletAddress',
          type: 'custom',
          params: {
            cellRenderer: RevealRoutingInfoRenderer,
            valueGetter: ({ data }: ValueGetterParams<CustomerAddressWhitelabel>) =>
              data && routingInfoMap.get(data.AddressID)?.WalletAddress,
          },
        },
        {
          id: 'Memo',
          type: 'custom',
          params: {
            cellRenderer: RevealRoutingInfoRenderer,
            valueGetter: ({ data }: ValueGetterParams<CustomerAddressWhitelabel>) =>
              data && routingInfoMap.get(data.AddressID)?.Memo,
          },
        },
      ] satisfies ColumnDef<CustomerAddressWhitelabel>[],
    [RevealRoutingInfoRenderer, routingInfoMap]
  );

  const persisted = usePersistedBlotterTable(BLOTTER_ID, { columns });
  const blotterTable = useWsBlotterTable<WebsocketRequest, CustomerAddressWhitelabel>({
    ...persisted,
    initialRequest: request,
    rowID: 'AddressID',
    density: BlotterDensity.Comfortable,
    rowHeight: 40,
  });

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader>
          <Box>
            <h2>
              <FormattedMessage {...messages.addresses} />
            </h2>
            <Box mt="spacingDefault">
              <Text whiteSpace="break-spaces">
                <FormattedMessage {...messages.pleaseAwaitConfirmation} />
              </Text>
            </Box>
          </Box>
        </PanelHeader>
        <PanelContent>
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
    </HStack>
  );
}
