import type { GridApi } from 'ag-grid-community';
import { values } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { NEVER, map } from 'rxjs';
import { useSocketClient, type SubscriptionStats } from '../../providers';
import { BlotterTable, useBlotterTable, type Column } from '../BlotterTable';
import { IconButton } from '../Button';
import { Box, HStack } from '../Core';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { Tooltip } from '../Tooltip';
interface StreamStatisticsBlotterProps {
  activeSubscriptions: boolean;
  onShowMessages: (messages: { reqid: string; messages: string }) => void;
  filterText: string;
}
export function StreamStatisticsBlotter(props: StreamStatisticsBlotterProps) {
  const client = useSocketClient();

  const { activeSubscriptions, onShowMessages, filterText } = props;

  const showRecords = useCallback(
    (data: SubscriptionStats) => {
      data.capturedMessages.length > 0 &&
        onShowMessages({ reqid: data.reqId, messages: formatData(data.capturedMessages) });
    },
    [onShowMessages]
  );

  const columns: Column[] = useMemo(() => {
    const columns: Column[] = [
      {
        type: 'text',
        field: 'reqId',
        title: 'ID',
        description: 'The stream request ID, unique for this session',
        width: 60,
      },
      {
        type: 'custom',
        field: 'sub',
        title: 'Subscription',
        description: 'The subscription name, hover to see the subscription request parameters',
        sort: '+',
        params: {
          cellRenderer: ({ data }: { data: SubscriptionStats }) => {
            return <Tooltip tooltip={<pre>{formatData(data.requestStreams)}</pre>}>{data.sub}</Tooltip>;
          },
        },
      },
      {
        type: 'text',
        field: 'tag',
        title: 'Tag',
        description:
          'The tag put on the subscription by the UI, used to help identify where in the UI this subscription was made from',
      },
      {
        type: 'text',
        field: 'totalMessages',
        title: 'Messages',
        description: 'The total number of websocket messages received on this stream',
        width: 110,
      },
      {
        type: 'text',
        title: 'Records',
        description: 'The total number of data records received from all messages',
        width: 110,
        field: 'totalRecords',
      },
      {
        type: 'text',
        field: 'recordsLastSecond',
        title: 'Records/s',
        description: 'The number of data records received in the last second',
        width: 90,
      },
      {
        type: 'text',
        field: 'loadTimeMs',
        title: 'Load Time (ms)',
        description:
          'How long after subscribing it took to receive the last page of data if loadAll=true, otherwise the time taken for the first message to be received on the stream.',
        width: 100,
      },
      {
        type: 'text',
        field: 'subscribedTime',
        title: 'Subscribed',
        description: 'The time the subscription was made',
        width: 120,
      },
    ];

    if (!activeSubscriptions) {
      columns.push({
        type: 'text',
        field: 'unsubscribedTime',
        title: 'Unsubscribed',
        description: 'The time the subscription was unsubscribed',
        width: 120,
      });
    } else {
      columns.push({
        type: 'modeWithMessage',
        field: 'isCapturedStream',
        title: 'Capture',
        description: 'Toggle to capture the stream data for inspection. Double click the row to view the records.',
        width: 80,
        params: {
          cellRendererParams: ({ data, api }: { data: SubscriptionStats; api: GridApi }) => {
            return {
              checked: data.isCapturedStream,
              onChange: (value: boolean) => {
                client.performance?.setCapturedStream(data.sub, value);
                api.refreshCells(); // to update row styles
              },
            };
          },
        },
      });
    }

    columns.push({
      type: 'custom',
      field: 'capturedMessages',
      title: '# Captured',
      params: {
        valueGetter: ({ data }: { data: SubscriptionStats }) => data.capturedMessages.length,
        cellRenderer: ({ data, value }: { data: SubscriptionStats; value: number }) => {
          return (
            value > 0 && (
              <HStack>
                <div>{value}</div>
                <IconButton
                  icon={IconName.Inbox}
                  ghost={true}
                  onClick={e => {
                    showRecords(data);
                    e.stopPropagation();
                  }}
                  size={FormControlSizes.Small}
                />
              </HStack>
            )
          );
        },
      },
    });

    return columns;
  }, [activeSubscriptions, client, showRecords]);

  const blotterTable = useBlotterTable({
    dataObservable: client.performance
      ? client.performance.stats().pipe(
          map(stats => ({
            data: values(props.activeSubscriptions ? stats.activeSubscriptionStats : stats.canceledSubscriptionStats),
          }))
        )
      : NEVER,
    columns,
    rowID: 'reqId',
    enableCellChangeFlash: true,
    renderEmpty: () => <div />,
    onDoubleClickRow(data) {
      showRecords(data);
    },
    getRowStyle: ({ data }) => {
      if (data?.capturedMessages && data.capturedMessages.length > 0) {
        return { cursor: 'pointer' };
      }
    },
    quickSearchParams: {
      entitySearchKeys: ['sub', 'tag'],
    },
  });

  useEffect(() => {
    blotterTable.blotterTableFiltersProps.onQuickFilterTextChanged(filterText);
  }, [filterText, blotterTable]);

  return (
    <Box h="70vh" w="1200px" gap="spacingTiny" overflow="hidden">
      <BlotterTable {...blotterTable} />
    </Box>
  );
}

function formatData(data: object) {
  return JSON.stringify(data, null, 2);
}
