import {
  Button,
  ButtonVariants,
  DecisionStatusEnum,
  FormControlSizes,
  FormattedMessage,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  OrdStatusEnum,
  Popover,
  WLCanModifyCustomerOrder,
  WLCanResubmitCustomerOrder,
  WL_CANCEL_ORDER,
  WL_INITIATE_ORDER,
  WL_MODIFY_ORDER,
  WL_PAUSE_ORDER,
  archiveOrderID,
  modifyOrder,
  resubmitOrderForm,
  setFocusedOrderID,
  useMixpanel,
  usePopoverState,
  useWLOrderFormDispatch,
  useWLRoleAuth,
  type CustomerOrder,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useGlobalCancelDialog, useOrdersProvider } from '../../../providers';

const messages = defineMessages({
  modify: {
    defaultMessage: 'Modify',
    id: 'Blotters.ActiveOrdersBlotter.modify',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Blotters.ActiveOrdersBlotter.cancel',
  },
  archive: {
    defaultMessage: 'Archive',
    id: 'Blotters.ActiveOrdersBlotter.archive',
  },
});

export const OrderActions = ({ data }: { data: CustomerOrder }) => {
  const { pauseOrder, resumeOrder } = useOrdersProvider();
  const dispatch = useWLOrderFormDispatch();
  const mixpanel = useMixpanel();

  const { open: openGlobalCancelDialog } = useGlobalCancelDialog();

  const openOrderStatuses = [
    OrdStatusEnum.New,
    OrdStatusEnum.PendingNew,
    OrdStatusEnum.PendingReplace,
    OrdStatusEnum.Holding,
    OrdStatusEnum.PartiallyFilled,
  ];

  const handleArchiveOrder = useCallback(() => {
    mixpanel.track(MixpanelEvent.ArchiveOrder, {
      [MixpanelEventProperty.ID]: data.OrderID,
    });
    dispatch(archiveOrderID(data.OrderID));
  }, [data, dispatch, mixpanel]);

  const orderFormDispatch = useWLOrderFormDispatch();
  const handleClickModifyOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      mixpanel.track(MixpanelEvent.OpenModifyOrder, {
        [MixpanelEventProperty.Source]: MixpanelEventSource.OrdersBlotter,
      });
      e.preventDefault();
      orderFormDispatch(modifyOrder(data));
      // Timeout here to dismiss focused order id
      setTimeout(() => {
        orderFormDispatch(setFocusedOrderID(null));
      });
    },
    [data, orderFormDispatch, mixpanel]
  );

  const handleClickCancelOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      openGlobalCancelDialog(data.OrderID);
      // Timeout here to dismiss focused order id
      setTimeout(() => {
        orderFormDispatch(setFocusedOrderID(null));
      });
    },
    [data.OrderID, openGlobalCancelDialog, orderFormDispatch]
  );
  const handlePauseOrder = useCallback(() => {
    pauseOrder(data.OrderID);
    orderFormDispatch(setFocusedOrderID(data.OrderID));
    mixpanel.track(MixpanelEvent.PauseOrder, { [MixpanelEventProperty.Source]: MixpanelEventSource.OrdersBlotter });
  }, [pauseOrder, data.OrderID, mixpanel, orderFormDispatch]);

  const handleResumeOrder = useCallback(() => {
    mixpanel.track(MixpanelEvent.ResumeOrder, {
      [MixpanelEventProperty.ID]: data.OrderID,
    });
    resumeOrder(data.OrderID);
    orderFormDispatch(setFocusedOrderID(data.OrderID));
  }, [data, resumeOrder, mixpanel, orderFormDispatch]);

  const handleResubmitOrder = useCallback(() => {
    dispatch(resubmitOrderForm(data));
    mixpanel.track(MixpanelEvent.OpenResubmitOrder, {
      [MixpanelEventProperty.ID]: data.OrderID,
    });
  }, [data, dispatch, mixpanel]);

  const popover = usePopoverState({
    trigger: 'click',
    usePortal: true,
    placement: 'bottom-end',
    closeOnClickOutside: true,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
    ],
  });

  const { isAuthorized } = useWLRoleAuth();

  const isOpenOrder = openOrderStatuses.includes(data.OrdStatus);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }} data-testid="order-actions">
      {isOpenOrder ? (
        <>
          {isAuthorized(WL_MODIFY_ORDER) && (
            <Button
              ghost
              onClick={handleClickModifyOrder}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Primary}
              disabled={!WLCanModifyCustomerOrder(data)}
              data-testid="order-actions-modify"
            >
              <FormattedMessage {...messages.modify} />
            </Button>
          )}
          {isAuthorized(WL_CANCEL_ORDER) && (
            <Button
              ghost
              onClick={handleClickCancelOrder}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Negative}
              data-testid="order-actions-cancel"
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          )}
        </>
      ) : (
        <Button ghost onClick={handleArchiveOrder} size={FormControlSizes.Small} data-testid="order-actions-archive">
          <FormattedMessage {...messages.archive} />
        </Button>
      )}

      {((isOpenOrder && isAuthorized(WL_PAUSE_ORDER)) || (!isOpenOrder && isAuthorized(WL_INITIATE_ORDER))) && (
        <Popover {...popover}>
          <IconButton icon={IconName.DotsVertical} ghost data-testid="order-actions-vertical-expand" />
          <div>
            {isOpenOrder ? (
              <>
                <div>
                  <Button
                    disabled={!isOpenOrder || data.DecisionStatus === DecisionStatusEnum.Paused}
                    onClick={handlePauseOrder}
                    size={FormControlSizes.Small}
                    data-testid="order-actions-pause"
                  >
                    Pause Order
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={!isOpenOrder || data.DecisionStatus !== DecisionStatusEnum.Paused}
                    onClick={handleResumeOrder}
                    size={FormControlSizes.Small}
                    data-testid="order-actions-resume"
                  >
                    Resume Order
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <Button
                  ghost
                  onClick={handleResubmitOrder}
                  size={FormControlSizes.Small}
                  disabled={!WLCanResubmitCustomerOrder(data)}
                  data-testid="order-actions-resubmit"
                >
                  Resubmit Order
                </Button>
              </div>
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};
