import { useWLOrgConfigContext } from '@talos/kyoko';
import type { HTMLAttributes } from 'react';
import styled, { type CSSProperties } from 'styled-components';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colorTextAttention};
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;
`;

const LogoImg = styled.img`
  max-height: 32px;
  min-width: 32px;
  height: 100%;
  max-width: 100%;
  width: ${({ width }) => width};
`;

export const Logo = styled(
  ({
    themeType,
    width,
    ...props
  }: { width?: CSSProperties['width']; themeType: 'dark' | 'light' } & HTMLAttributes<HTMLDivElement>) => {
    const { config } = useWLOrgConfigContext();
    const logoUrl = `/customers/${config.logo?.[themeType]}`;

    return (
      <LogoWrapper {...props}>
        {config.logo?.[themeType] ? <LogoImg width={width} src={logoUrl ?? ''} alt="" /> : 'Company Name'}
      </LogoWrapper>
    );
  }
)``;
