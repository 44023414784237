import { useCallback, useMemo, useState } from 'react';
import { of } from 'rxjs';
import { useObservableValue } from '../../hooks';
import { useSocketClient } from '../../providers';
import { AccordionGroup } from '../Accordion';
import { HStack } from '../Core';
import { FilterInput } from '../FilterInput';
import { TextArea, Toggle } from '../Form';
import { Tab, TabList, TabPanel, TabSize, Tabs, useTabs } from '../Tabs';
import { StreamStatisticsBlotter } from './StreamDiagnosticsBlotter';
import { DataTextArea } from './styles';

export function StreamDiagnosticsDialog() {
  const client = useSocketClient();
  const [shownMessages, setShownMessages] = useState<{ reqid: string; messages: string }>();
  const [filterText, setFilterText] = useState('');

  const tabItems = useMemo(() => {
    return [
      {
        label: 'Active Subscriptions',
        render: () => (
          <StreamStatisticsBlotter
            activeSubscriptions={true}
            onShowMessages={setShownMessages}
            filterText={filterText}
          />
        ),
      },
      {
        label: 'Cancelled Subscriptions',
        render: () => (
          <StreamStatisticsBlotter
            activeSubscriptions={false}
            onShowMessages={setShownMessages}
            filterText={filterText}
          />
        ),
      },
    ];
  }, [filterText]);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: tabItems,
  });

  const isEnabled = useObservableValue<boolean>(
    () => client.performance?.isLogSubscriptionInfo$ ?? of(false),
    [client.performance?.isLogSubscriptionInfo$]
  );

  const onEnabledClick = useCallback(() => {
    client.performance?.setLogSubscriptionInfo(!isEnabled);
  }, [isEnabled, client.performance]);

  return (
    <Tabs {...tabs} size={TabSize.Large} onClick={() => setShownMessages(undefined)}>
      <TabList
        isBordered={true}
        rightItems={
          <HStack gap={10}>
            <FilterInput onChange={setFilterText} value={filterText} />
            <Toggle checked={!!isEnabled} onChange={onEnabledClick} label={isEnabled ? 'Enabled' : 'Disabled'} />
          </HStack>
        }
      >
        {tabItems.map((tab, index) => (
          <Tab key={`${tab.label}-${index}`} label={tab.label} />
        ))}
      </TabList>
      {tabItems.map((tab, index) => {
        const hidden = tabs.selectedIndex !== index;
        return (
          <TabPanel hidden={hidden} key={`${tab.label}-${index}-${isEnabled}`} display="flex" flexDirection="column">
            <AccordionGroup>
              {!hidden && (
                <HStack>
                  {tab.render()}
                  {shownMessages && (
                    <DataTextArea key={shownMessages.reqid}>
                      <TextArea
                        width={580}
                        height="70vh"
                        onClick={evt => evt.stopPropagation()}
                        defaultValue={shownMessages.messages}
                      />
                    </DataTextArea>
                  )}
                </HStack>
              )}
            </AccordionGroup>
          </TabPanel>
        );
      })}
    </Tabs>
  );
}
