import { getAgGridColId, useWLOrgConfigContext, type AgGridCurrencyParams, type Column } from '@talos/kyoko';
import { useMemo } from 'react';
import { OrderActions } from '../OrderActions';

export function useActiveOrdersBlotterColumn() {
  const { config: customerConfig } = useWLOrgConfigContext();

  return useMemo(() => {
    return COLUMNS.filter(col => {
      if (getAgGridColId(col) === 'CustomerUser') {
        return !customerConfig.hideUserEmail;
      }
      return true;
    });
  }, [customerConfig.hideUserEmail]);
}

const COLUMNS: Column[] = [
  { type: 'orderStatus', field: 'OrdStatus' },
  { type: 'date', field: 'SubmitTime', sortable: true },
  { type: 'account', field: 'MarketAccount' },
  { type: 'security', field: 'Symbol' },
  { type: 'side', field: 'Side' },
  { type: 'size', field: 'OrderQty', params: { currencyField: 'Currency' } },
  {
    type: 'currency',
    field: 'Currency',
    id: 'qtyCurrency',
    titleIntlKey: 'qtyCurrency',
    hide: true,
  } satisfies Column<AgGridCurrencyParams>,
  { type: 'filledPercent', id: 'filledPercent', params: { showLabel: false } },
  { type: 'size', field: 'CumQty', params: { currencyField: 'Currency' } },
  {
    type: 'price',
    field: 'Price',
    params: { assetField: 'Symbol', quoteCurrencyField: 'QuoteCurrency' },
  },
  {
    type: 'currency',
    id: 'priceCurrency',
    titleIntlKey: 'priceCurrency',
    hide: true,
    params: {
      symbolField: 'Symbol',
      securityCurrencyField: 'QuoteCurrency',
    },
  } satisfies Column<AgGridCurrencyParams>,
  {
    type: 'price',
    field: 'AvgPx',
    params: { assetField: 'Symbol', quoteCurrencyField: 'QuoteCurrency' },
  },
  { type: 'size', field: 'CumAmt', titleIntlKey: 'value', params: { currencyField: 'AmountCurrency' } },
  { type: 'strategy', field: 'Strategy' },
  { type: 'id', field: 'OrderID' },
  { type: 'filler', id: 'filler1' },
  {
    type: 'user',
    field: 'CustomerUser',
    params: {
      valueFormatWithMap: 'email',
    },
  },
  {
    type: 'custom',
    id: 'orderActions',
    suppressColumnsToolPanel: true,
    params: {
      menuTabs: [],
      resizable: false,
      pinned: 'right',
      width: 170,
      lockVisible: true,
      lockPinned: true,
      cellRenderer: OrderActions,
      type: 'rightAligned',
    },
  },
];
