import {
  AccordionGroup,
  BlotterSettingsProvider,
  CurrenciesProvider,
  CurrencyConversionProvider,
  DialogProvider,
  EndpointsContextProvider,
  GlobalToastsProvider,
  MarketPriceContextProvider,
  MixpanelProvider,
  NotesProvider,
  OrderServiceProvider,
  RFQServiceProvider,
  SecuritiesProvider,
  WLBalancesProvider,
  WLCustomerMarketAccountsProvider,
  WLCustomerUserConfigContextProvider,
  WLExposuresProvider,
  WLFavoriteSecuritiesProvider,
  WLHomeCurrencyProvider,
  WLOrgConfigProvider,
  WLTradingLimitsProvider,
  WLUserContextProvider,
  WLUsersContextProvider,
  WebSocketClientProvider,
  composeComponents,
} from '@talos/kyoko';
import {
  BlotterTableContextProvider,
  BlotterTableStorageProvider,
  CustomerDepositWithdrawProvider,
  GlobalCancelDialogProvider,
  IntlProvider,
  MarketDataCardsProvider,
  OMSStateProvider,
  OrdersProvider,
  ProvidersGate,
  QuotesProvider,
  RecentSymbolsProvider,
  RefRateContextProvider,
  SparklineProvider,
  StrategiesProvider,
  ThemeProvider,
  WatchlistSettingsContextProvider,
  WhitelabelOrderFormProvider,
} from '../../providers';

export const EssentialProviders = composeComponents([
  EndpointsContextProvider,
  WLOrgConfigProvider,
  WebSocketClientProvider,
  WLUserContextProvider,
  MixpanelProvider,
  WLCustomerMarketAccountsProvider,
  WLCustomerUserConfigContextProvider,
  IntlProvider,
]);

export const Providers = composeComponents([
  NotesProvider,
  GlobalToastsProvider,
  RecentSymbolsProvider,
  CurrenciesProvider,
  SecuritiesProvider,
  StrategiesProvider,
  WLBalancesProvider,
  OrdersProvider,
  QuotesProvider,
  WLHomeCurrencyProvider,
  WLUsersContextProvider,
  CurrencyConversionProvider,
  BlotterTableStorageProvider,
  BlotterTableContextProvider,
  WLExposuresProvider,
  CustomerDepositWithdrawProvider,
  WLTradingLimitsProvider,
  ProvidersGate,
  DialogProvider,
  WatchlistSettingsContextProvider,
  WLFavoriteSecuritiesProvider,
  RefRateContextProvider,
  MarketPriceContextProvider,
  SparklineProvider,
  AccordionGroup,
  BlotterSettingsProvider,

  // Theme
  ThemeProvider,

  // Providers requiring theme
  WhitelabelOrderFormProvider,
  OMSStateProvider,
  OrderServiceProvider,
  RFQServiceProvider,
  GlobalCancelDialogProvider,
]);

// These Providers are used in Whitelabel Cypress Tests
export const CypressTestProviders = composeComponents([
  NotesProvider,
  WebSocketClientProvider,
  GlobalToastsProvider,
  WLUserContextProvider,
  WLOrgConfigProvider,
  WLCustomerMarketAccountsProvider,
  WLCustomerUserConfigContextProvider,
  IntlProvider,
  CurrenciesProvider,
  SecuritiesProvider,
  StrategiesProvider,
  WLBalancesProvider,
  OrdersProvider,
  QuotesProvider,
  WLHomeCurrencyProvider,
  WLUsersContextProvider,
  CurrencyConversionProvider,
  BlotterTableStorageProvider,
  BlotterTableContextProvider,
  WLExposuresProvider,
  WLTradingLimitsProvider,
  ProvidersGate,
  DialogProvider,
  MarketDataCardsProvider,
  CustomerDepositWithdrawProvider,
  AccordionGroup,

  ThemeProvider,

  WhitelabelOrderFormProvider,
  OMSStateProvider,
  OrderServiceProvider,
  RFQServiceProvider,
  GlobalCancelDialogProvider,
]);
