import * as Sentry from '@sentry/browser';
import Big from 'big.js';
import { compact, uniq } from 'lodash';
import type React from 'react';
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Observable } from 'rxjs';
import { map, scan, shareReplay } from 'rxjs/operators';
import { useSecuritiesContext } from '../contexts';
import { useHomeCurrencyRatesValue, useObservable, useObservableRef, useStaticSubscription } from '../hooks';
import { MAX_ORDER_SIZE_LIMIT } from '../tokens';
import {
  UpdateActionEnum,
  type CustomerTradingLimit,
  type SubscriptionResponse,
  type WLTradingLimitsProps,
  type WLTradingLimitsValidationResult,
} from '../types';
import { useWLHomeCurrency } from './WLCustomerUserConfigContextProvider';
import { useWLUser } from './WLUserContextProvider';

export interface ConversionRequest {
  name: string;
  tag: string;
  EquivalentCurrency: string;
  Currencies: string[];
}

export interface TradingLimitsContextProps {
  maxOrderSizeByKey: Observable<Map<string, CustomerTradingLimit>>;
}

type CustomerTradingLimitKey = Pick<
  CustomerTradingLimit,
  'CustomerUser' | 'MarketAccount' | 'Counterparty' | 'Symbol' | 'Currency'
>;

export const TradingLimitsContext = createContext<TradingLimitsContextProps | undefined>(undefined);

export const useMaxOrderSizeByKey = () => {
  const context = useContext(TradingLimitsContext);
  if (context === undefined) {
    throw new Error('Missing TradingLimitsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

// Bitmasks
const MATCHERS = {
  NONE: 0,
  CUSTOMER_USER: 1,
  MARKET_ACCOUNT: 2,
  COUNTERPARTY: 4,
  SYMBOL: 8,
  CURRENCY: 16,
};

const RULES = [
  MATCHERS.CUSTOMER_USER | MATCHERS.MARKET_ACCOUNT | MATCHERS.SYMBOL,
  MATCHERS.CUSTOMER_USER | MATCHERS.MARKET_ACCOUNT | MATCHERS.CURRENCY,
  MATCHERS.CUSTOMER_USER | MATCHERS.MARKET_ACCOUNT,
  MATCHERS.CUSTOMER_USER | MATCHERS.SYMBOL,
  MATCHERS.CUSTOMER_USER | MATCHERS.CURRENCY,
  MATCHERS.CUSTOMER_USER,
  MATCHERS.MARKET_ACCOUNT | MATCHERS.SYMBOL,
  MATCHERS.MARKET_ACCOUNT | MATCHERS.CURRENCY,
  MATCHERS.MARKET_ACCOUNT,
  MATCHERS.COUNTERPARTY | MATCHERS.SYMBOL,
  MATCHERS.COUNTERPARTY | MATCHERS.CURRENCY,
  MATCHERS.COUNTERPARTY,
  MATCHERS.SYMBOL,
  MATCHERS.CURRENCY,
  MATCHERS.NONE,
];

export const createLimitMatcher = (limit: CustomerTradingLimit) => {
  let matcher = MATCHERS.NONE;
  if (limit.CustomerUser) {
    matcher |= MATCHERS.CUSTOMER_USER;
  }
  if (limit.MarketAccount) {
    matcher |= MATCHERS.MARKET_ACCOUNT;
  }
  if (limit.Counterparty) {
    matcher |= MATCHERS.COUNTERPARTY;
  }
  if (limit.Symbol) {
    matcher |= MATCHERS.SYMBOL;
  }
  if (limit.Currency) {
    matcher |= MATCHERS.CURRENCY;
  }
  return matcher;
};

export const createLimitKey = (
  matcher: number,
  limit: Pick<CustomerTradingLimit, 'CustomerUser' | 'MarketAccount' | 'Counterparty' | 'Symbol' | 'Currency'>
) => {
  const key: CustomerTradingLimitKey = {};
  if (matcher & MATCHERS.CUSTOMER_USER) {
    key.CustomerUser = limit.CustomerUser;
  }
  if (matcher & MATCHERS.MARKET_ACCOUNT) {
    key.MarketAccount = limit.MarketAccount;
  }
  if (matcher & MATCHERS.COUNTERPARTY) {
    key.Counterparty = limit.Counterparty;
  }
  if (matcher & MATCHERS.SYMBOL) {
    key.Symbol = limit.Symbol;
  }
  if (matcher & MATCHERS.CURRENCY) {
    key.Currency = limit.Currency;
  }
  return JSON.stringify(key);
};

export function mapMaxOrderSizeLimits(
  source: Observable<SubscriptionResponse<CustomerTradingLimit & { TradingLimitID: string }>>
) {
  return source.pipe(
    scan(
      (
        {
          byID,
          byKey,
        }: {
          byID: Map<string, CustomerTradingLimit & { TradingLimitID: string }>;
          byKey: Map<string, CustomerTradingLimit & { TradingLimitID: string }>;
        },
        json
      ) => {
        for (const limit of json.data) {
          const keyOfNewLimit = createLimitKey(createLimitMatcher(limit), limit);
          const existingLimitResolvedFromKey = byKey.get(keyOfNewLimit);

          // This if statement checks to see that there aren't two UNIQUE limits both resolving to one and the same KEY
          // The "key" in this context is a definition of what the limit matches on
          // We don't support handling two unique limits both resolving to one matching key.
          if (
            existingLimitResolvedFromKey != null &&
            existingLimitResolvedFromKey.TradingLimitID !== limit.TradingLimitID
          ) {
            Sentry.captureMessage(`Max order size limit key matches multiple entries, ignoring limit`, {
              level: 'error',
              extra: { maxOrderSizeLimit: JSON.stringify(limit) },
            });
            continue;
          }

          const oldLimit = byID.get(limit.TradingLimitID);
          if (oldLimit != null) {
            // If there exists a limit by our ID from before, its also in the "by key" set.
            // So compute the key and make sure to remove that entry from the byKey map.
            const oldMatcher = createLimitMatcher(oldLimit);
            const oldKey = createLimitKey(oldMatcher, oldLimit);
            byKey.delete(oldKey);
          }

          switch (limit.UpdateAction) {
            case UpdateActionEnum.Update:
              byID.set(limit.TradingLimitID, limit);
              byKey.set(keyOfNewLimit, limit);
              break;
            case UpdateActionEnum.Remove:
              byID.delete(limit.TradingLimitID);
              break;
          }
        }
        return { byID, byKey };
      },
      {
        byID: new Map(),
        byKey: new Map(),
      }
    )
  );
}

export const WLTradingLimitsProvider = memo(({ children }: React.PropsWithChildren<unknown>) => {
  const { data: maxOrderSizeSubscription } = useStaticSubscription({
    name: MAX_ORDER_SIZE_LIMIT,
    tag: 'WLTradingLimitsProvider',
  });

  const maxOrderSizeObs = useObservable<{
    byKey: Map<string, CustomerTradingLimit & { TradingLimitID: string }>;
  }>(
    () =>
      maxOrderSizeSubscription.pipe(
        mapMaxOrderSizeLimits,
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [maxOrderSizeSubscription]
  );

  const maxOrderSizeByKey = useObservable<Map<string, CustomerTradingLimit>>(
    () =>
      maxOrderSizeObs.pipe(
        map(({ byKey }) => byKey),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [maxOrderSizeObs]
  );

  const value = useMemo(() => ({ maxOrderSizeByKey }), [maxOrderSizeByKey]);
  return <TradingLimitsContext.Provider value={value}>{children}</TradingLimitsContext.Provider>;
});

export function useWLTradingLimits({
  quantity,
  currency,
  symbol,
  marketAccountName,
}: WLTradingLimitsProps): WLTradingLimitsValidationResult {
  const { homeCurrency } = useWLHomeCurrency();

  const { maxOrderSizeByKey } = useMaxOrderSizeByKey();
  const maxOrderSizeByKeyRef = useObservableRef(() => maxOrderSizeByKey, [maxOrderSizeByKey]);
  const { securitiesBySymbol } = useSecuritiesContext();
  const user = useWLUser();

  const [currencies, setCurrencies] = useState<string[]>([]);

  useEffect(() => {
    const sub = maxOrderSizeByKey.subscribe((limits: Map<string, CustomerTradingLimit>) => {
      if (homeCurrency != null) {
        const limitCurrencies = uniq(
          [...limits.values()].map<string>(({ ThresholdCurrency }) => ThresholdCurrency)
        ).sort();
        if (limitCurrencies.length) {
          const currencies: Set<string> = new Set(limitCurrencies);
          [...securitiesBySymbol.values()].forEach(security => {
            currencies.add(security.BaseCurrency);
            currencies.add(security.QuoteCurrency);
          });
          setCurrencies([...currencies]);
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [homeCurrency, maxOrderSizeByKey, securitiesBySymbol]);

  const homeCurrencyRates = useHomeCurrencyRatesValue(currencies);
  const conversionRates = useMemo(
    () => new Map(homeCurrencyRates?.map(rate => [rate.Asset, rate])),
    [homeCurrencyRates]
  );

  const getMatchingCustomerTradeLimit: () => CustomerTradingLimit | undefined = useCallback(() => {
    const maxOrderSize = maxOrderSizeByKeyRef.current;
    if (maxOrderSize != null && symbol != null) {
      const counterparty = [...maxOrderSize.values()].find(limit => limit.Counterparty)?.Counterparty;
      for (const matcher of RULES) {
        // Check if security or currency matches
        let currencies: (string | undefined)[] = [undefined];
        if (matcher & MATCHERS.CURRENCY) {
          const security = securitiesBySymbol.get(symbol);
          currencies = compact([security?.BaseCurrency, security?.QuoteCurrency]);
        }
        for (const currency of currencies) {
          const key = createLimitKey(matcher, {
            Symbol: symbol,
            Currency: currency,
            CustomerUser: user.Email,
            Counterparty: counterparty,
            MarketAccount: marketAccountName,
          });
          if (maxOrderSize.has(key)) {
            return maxOrderSize.get(key);
          }
        }
      }
    }
  }, [marketAccountName, maxOrderSizeByKeyRef, securitiesBySymbol, symbol, user.Email]);

  const validationResult = useMemo(() => {
    const matchingLimit = getMatchingCustomerTradeLimit();
    if (matchingLimit) {
      const limitConversion = conversionRates.get(matchingLimit.ThresholdCurrency)?.Rate;
      const quantityConversion = currency ? conversionRates.get(currency)?.Rate : undefined;
      if (limitConversion != null && quantityConversion != null && quantity) {
        const sizeHomeCurrency = Big(quantity).times(quantityConversion);
        const rejectLimitInHomeCurrency = Big(matchingLimit.RejectThreshold).times(limitConversion);
        const warnLimitInHomeCurrency = Big(matchingLimit.WarnThreshold).times(limitConversion);

        if (sizeHomeCurrency.gt(rejectLimitInHomeCurrency)) {
          return { warn: true, reject: true, limit: matchingLimit };
        } else if (sizeHomeCurrency.gt(warnLimitInHomeCurrency)) {
          return { warn: true, reject: false, limit: matchingLimit };
        }
      }
    }

    return { warn: false, reject: false, limit: undefined };
  }, [conversionRates, currency, getMatchingCustomerTradeLimit, quantity]);

  return validationResult;
}
